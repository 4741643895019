import { ProductAttributeValue as SourceProductAttributeValue } from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.override.style';

export * from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

/** @namespace SourceComponent/ProductAttributeValue/Component */
export class ProductAttributeValue extends SourceProductAttributeValue {

}

export default ProductAttributeValue;
