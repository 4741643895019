/* eslint-disable */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import SubscriptionCartItem from './SubscriptionCartItem.component';

/** @namespace Hoeks/Subscription/Component/SubscriptionCartItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartData: state.CartReducer.cartTotals
});

/** @namespace Hoeks/Subscription/Component/SubscriptionCartItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({

});

/** @namespace Hoeks/Subscription/Component/SubscriptionCartItem/Container/SubscriptionCartItemContainer */
export class SubscriptionCartItemContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    render() {
        return (
            <SubscriptionCartItem
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionCartItemContainer);
