/* eslint-disalbe */
import ProductAlert from '../component/ProductAlert';

const renderProductAlert = (args, callback, instance) => {
    const { 
        product,
        product: {
            stock_status = "IN_STOCK"
        }
    } = instance.props;
    return (
        <>
            {stock_status === "OUT_OF_STOCK" ? <ProductAlert product={product} /> :callback(...args)}
        </>
    )
}

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            'renderAddToCart': {
                implementation: renderProductAlert,
                position: 1
            }
        }
    }
}