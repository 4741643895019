import { ProductAlertComponent as SourceProductAlertComponent } from '@hoeks/product-alert/src/component/ProductAlert/ProductAlert.component';

import './ProductAlert.override.style';

/** @namespace Hoeks/product-alert/Component/ProductAlert/Component/ProductAlertComponent */
export class ProductAlertComponent extends SourceProductAlertComponent {

}

export default ProductAlertComponent;
