import BrowserDatabase from 'Util/BrowserDatabase';
import ClerkQuery from '../../query/Clerk.query';
import { fetchQuery } from 'Util/Request';
export const VISITORID_CONFIG = 'clerk_visitor_id';
export const KEY_CONFIG = 'clerk_store_id';
export const API_URL = 'https://api.clerk.io/v2/';

export const generate_token = (length) => {
    var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
    var b = [];  
    for (var i=0; i<length; i++) {
        var j = (Math.random() * (a.length-1)).toFixed(0);
        b[i] = a[j];
    }
    return b.join("");
}

export const getVisitorId = () => {
    const id = BrowserDatabase.getItem(VISITORID_CONFIG);
    if (!id){
        const newId = generate_token(8);
        BrowserDatabase.setItem(newId, VISITORID_CONFIG);
        return newId;
    }
    return id;
}

export const getStoreKey = async () => {
    var browserKey = BrowserDatabase.getItem(KEY_CONFIG);
    if (browserKey == undefined || browserKey == null) {
        const {storeConfig: {clerk_api_key = ''} = {}} = await fetchQuery(ClerkQuery.getApiKey())
        
        if (clerk_api_key == '') {
            return;
        }
        BrowserDatabase.setItem(clerk_api_key, KEY_CONFIG);
        browserKey = clerk_api_key;
    }

    if (browserKey == null || browserKey == undefined) {
        console.error('missing clerk public key');
    }
    return browserKey;
};

export const callApi = async (requestPath, data, callback, errorCallback) => {
    var storeKey = await getStoreKey();    
    const payloadObject = {...data, visitor: getVisitorId(), key: storeKey, _: new Date().getTime() }
    const payload = `?payload=${encodeURIComponent(JSON.stringify(payloadObject))}`;
    fetch(API_URL+requestPath+payload)
      .then(res => res.json())
      .then(
        (json) => {
            if (json.status && json.status == 'error') {
                errorCallback(json.message);
            } else {
                callback(json);
            }
        },errorCallback
      );
}


