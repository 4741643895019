/* eslint-disable */

const getStoreConfigField = (args, callback, instance) => {
    return [
        ...callback(...args),
        'store_id'
    ]
}

export default {
    'Query/Config': {
        'member-function': {
            '_getStoreConfigFields': getStoreConfigField
        }
    }
}