/* eslint-disable */
import { ClerkSearchContainer as SourceClerkSearchContainer, mapStateToProps, mapDispatchToProps} from '@hoeks/clerk/src/component/ClerkSearch/ClerkSearch.container';
export * from '@hoeks/clerk/src/component/ClerkSearch/ClerkSearch.container';
import { connect } from 'react-redux';

export class ClerkSearchContainer extends SourceClerkSearchContainer {
    goToSearchPage(query, trimmedSearch) {
        super.goToSearchPage(query, trimmedSearch);
        window.scrollTo(0, 0);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClerkSearchContainer);
