// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ProductAlert from './ProductAlert.component';
import { fetchQuery } from 'Util/Request';
import { showNotification } from 'Store/Notification/Notification.action';
import { connect } from 'react-redux';
import ProductAlertQuery from '../../query/ProductAlert.query';

export const mapStateToProps = (state) => ({
    store_id: state.ConfigReducer.store_id
});

export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
})

/** @namespace Hoeks/ProductAlert/Component/ProductAlert/Container/ProductAlertContainer */
export class ProductAlertContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
        onFormSuccess: this.onFormSuccess.bind(this),
        onFormError: this.onFormError.bind(this)
    };

    containerProps() {
        // isDisabled: this._getIsDisabled()
    }

    onFormSuccess(fields) {
        const { showErrorNotification, showSuccessNotification, product: {id}, store_id = 0 } = this.props;
        const { emailwarding = "", email = null } = fields;
        if (email) {
            // console.log("A bot tried to use the form");
            return;
        }

        try {
            fetchQuery(ProductAlertQuery.addEmailToProductAlert(emailwarding, id, store_id)).then(({hoeksProductAlert: response}) => {
                const {message, status} = response;
                if (status) {
                    showSuccessNotification(message);
                } else {
                    showErrorNotification(message);
                }
            })
        } catch (error) {
            console.error(error);
        }
    }

    onFormError(fields) {
        console.error(fields);
    }

    render() {
        return (
            <ProductAlert
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAlertContainer);
