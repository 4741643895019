/* eslint-disable */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './SubscriptionCartItem.style';

/** @namespace Hoeks/Subscription/Component/SubscriptionCartItem/Component/SubscriptionCartItemComponent */
export class SubscriptionCartItemComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        const { billingPeriod, subscriptionStartDate } = this.props;
        
        if (billingPeriod == "") return (<div></div>);

        return (
            <div block="SubscriptionCartItem">
                <dl block="Details" elem="List">
                    <dt>{__('Billing Period')}</dt>
                    <dd>{billingPeriod}</dd>
                    <dt>{__('Subscription Start Date')}</dt>
                    <dd>{subscriptionStartDate}</dd>
                </dl>
            </div>
        );
    }
}

export default SubscriptionCartItemComponent;
