/* eslint-disable */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import SubscriptionDetails from './SubscriptionDetails.component';

export const SubscriptionProfileDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/SubscriptionProfile/SubscriptionProfile.dispatcher'
);

/** @namespace Hoeks/Subscription/Component/SubscriptionDetails/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    subscription: state.SubscriptionProfileReducer.subscription.subscription,
    isLoading: state.SubscriptionProfileReducer.isLoading
});

/** @namespace Hoeks/Subscription/Component/SubscriptionDetails/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    getSubscriptionData: (subscription_id) => SubscriptionProfileDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getSubscriptionData(_dispatch, subscription_id)
    ),
    cancelSubscription: (subscription_id) => SubscriptionProfileDispatcher.then(
        ({ default: dispatcher }) => dispatcher.cancelSubscription(_dispatch, subscription_id)
    ),
    getSubscriptionProfileData: () => SubscriptionProfileDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getSubscriptionProfileData(_dispatch)
    ),
    updateIsLoading: (loadingStatus) => SubscriptionProfileDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateIsLoading(_dispatch, loadingStatus)
    )
});

/** @namespace Hoeks/Subscription/Component/SubscriptionDetails/Container/SubscriptionDetailsContainer */
export class SubscriptionDetailsContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
        onCancelClicked: this.onCancelClicked.bind(this),
        getPrice: this.getPrice.bind(this)
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };
    componentDidUpdate() {
        const { getSubscriptionData, subscription, isLoading, updateIsLoading } = this.props;
        if (subscription == undefined) {
            updateIsLoading(true);
            getSubscriptionData(this.getSubscriptionId());
        }
    }

    componentDidMount() {
        const { getSubscriptionData, subscription, updateIsLoading } = this.props;
        updateIsLoading(true);
        getSubscriptionData(this.getSubscriptionId());
    }

    getSubscriptionId() {
        const url = new URL(window.location.href);
        return url.searchParams.get('id');
    }

    onCancelClicked() {
        if (confirm(__('Are you sure ? You want to cancel this subscription profile.')) == false) return;

        const { cancelSubscription, getSubscriptionData, getSubscriptionProfileData, updateIsLoading } = this.props;
        var subscriptionId = this.getSubscriptionId();
        cancelSubscription(subscriptionId).then(() => {
            updateIsLoading(true);
            getSubscriptionData(subscriptionId);
            getSubscriptionProfileData();
        });
    }

    getPrice() {
        const { subscription: {billing_amount, tax_amount, currency_code, qty_subscribed} } = this.props;
        const price = (billing_amount + tax_amount) * qty_subscribed;
        return `${price.toFixed(2)} ${currency_code}`;
    }

    render() {
        return (
            <SubscriptionDetails
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDetailsContainer);
