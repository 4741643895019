/* eslint-disable */
const _getAddressById = (args, callback, instance) => {
    const [id] = args;
    const result = {...callback(...args), customer_address_id: id};
    return result;
}
const _getAddress = (args, callback, instance) => {
    const {
        isSameAsShipping,
        selectedCustomerAddressId
    } = instance.state;
    const result = callback(...args);
    if (!isSameAsShipping && selectedCustomerAddressId > 0){
        return {...result, customer_address_id: selectedCustomerAddressId };
    }
    return result;
}

export default {
    'Component/CheckoutShipping/Container': {
        'member-function': {
            _getAddressById: _getAddressById
        }
    },
    'Component/CheckoutBilling/Container': {
        'member-function': {
            _getAddress: _getAddress
        }
    }
}