export const GET_SUBSCRIPTION_PROFILES = 'GET_SUBSCRIPTION_PROFILES';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const UPDATE_IS_LOADING = "UPDATE_IS_LOADING";

/** @namespace Store/SubscriptionProfile/Action/getSubscriptionProfiles */
export const getSubscriptionProfiles = (subscriptionProfiles, status) => ({
    type: GET_SUBSCRIPTION_PROFILES,
    subscriptionProfiles,
    status
});

/** @namespace Store/subscriptionProfile/action/getSubscription */
export const getSubscription = (subscription, subscriptionStatus) => ({
    type: GET_SUBSCRIPTION,
    subscription,
    subscriptionStatus
});

/** @namespace Store/subscriptionProfile/action/updateSubscription */
export const updateSubscriptionAction = (newSubscription, data, updateStatus) => ({
    type: UPDATE_SUBSCRIPTION,
    newSubscription,
    data,
    updateStatus
});

/** @namespace Store/subscriptionProfile/action/updateIsLoading */
export const updateIsLoading = (loadingStatus) => ({
    type: UPDATE_IS_LOADING,
    loadingStatus
});