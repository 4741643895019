/* eslint-disable */
import PriceSlider from '../component/PriceSlider';

const renderConfigurableAttributes = (args, callback, instance) => {
    const { updateConfigurableVariant } = instance.props;
    return (
        <>
            { callback.apply(instance, args) }
            <PriceSlider toggleCustomFilter={ updateConfigurableVariant } />
        </>
    );
}

export default {
    'Component/CategoryConfigurableAttributes/Component': {
        'member-function': {
            renderConfigurableAttributes
        }
    }
}