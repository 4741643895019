/* eslint-disable */
import { ProductPriceContainer as SourceProductPriceContainer, mapDispatchToProps, mapStateToProps } from 'SourceComponent/ProductPrice/ProductPrice.container';
import { connect } from 'react-redux';
import {
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_BOTH,
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX,
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX
} from './ProductPrice.config';
import {
    calculateFinalPrice,
    formatPrice,
    roundPrice
} from 'Util/Price';

export class ProductPriceContainer extends SourceProductPriceContainer {
    containerProps = () => {
        const {
            price: {
                minimum_price: {
                    discount: {
                        amount_off: discountFixed,
                        percent_off: discountPercentage
                    } = {},
                    final_price: {
                        value: minimalPriceValue,
                        currency: priceCurrency
                    } = {},
                    regular_price: {
                        value: regularPriceValue
                    } = {},
                    default_price: {
                        value: defaultPriceValue
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if ((!minimalPriceValue || !regularPriceValue) && !defaultPriceValue) {
            return {};
        }

        const roundedRegularPrice = this.getRoundedRegularPrice();
        const formattedFinalPrice = this.getFormattedFinalPrice();
        const formattedSubPrice = this.getFormattedSubPrice();

        return {
            roundedRegularPrice,
            priceCurrency,
            discountFixed,
            discountPercentage,
            formattedFinalPrice,
            formattedSubPrice
        };
    };

    getRoundedRegularPrice() {
        const {
            price: {
                minimum_price: {
                    regular_price: {
                        value: regularPriceValue,
                        currency: priceCurrency
                    } = {},
                    regular_price_excl_tax: {
                        value: regularPriceExclTaxValue
                    } = {}
                } = {}
            } = {},
            displayTaxInPrice
        } = this.props;

        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX) {
            return formatPrice(roundPrice(regularPriceExclTaxValue), priceCurrency);
        }

        return formatPrice(roundPrice(regularPriceValue), priceCurrency);
    }

    getFormattedFinalPrice() {
        const {
            price: {
                minimum_price: {
                    discount: {
                        amount_off: discountFixed,
                        percent_off: discountPercentage
                    } = {},
                    final_price: {
                        value: minimalPriceValue,
                        currency: priceCurrency
                    } = {},
                    final_price_excl_tax: {
                        value: minimalPriceExclTaxValue
                    } = {},
                    regular_price: {
                        value: regularPriceValue
                    } = {},
                    regular_price_excl_tax: {
                        value: regularPriceExclTaxValue
                    } = {}
                } = {}
            } = {},
            displayTaxInPrice
        } = this.props;

        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX) {
            const finalPrice = calculateFinalPrice(
                discountPercentage,
                minimalPriceExclTaxValue,
                regularPriceExclTaxValue
            );

            return formatPrice(finalPrice, priceCurrency);
        }

        if (discountFixed) {
            const finalPrice = calculateFinalPrice(discountFixed, minimalPriceValue, regularPriceValue);   
            return formatPrice(finalPrice, priceCurrency); 
        }

        const finalPrice = calculateFinalPrice(discountPercentage, minimalPriceValue, regularPriceValue);

        return formatPrice(finalPrice, priceCurrency);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);