/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */

import { Route } from 'react-router-dom';
import { withStoreRegex } from 'Component/Router/Router.component';

import PostsDetails from '../route/PostsDetails';
import PostsListing from '../route/PostsListing';
import { BLOG_PATH } from '../route/PostsListing/PostsListing.config';

export class RouterPlugin {
  /**
   * Adds blog url handles to the router SWITCH_ITEMS_TYPE list
   * @param originalMember
   * @returns {*[]}
   */
  switchItems = (originalMember) => [
    ...originalMember,
    {
      component: <Route path={ withStoreRegex(`${BLOG_PATH}/:handle`) } component={PostsDetails} />,
      position: 100,
    },
    {
      component: <Route path={ withStoreRegex(BLOG_PATH) } component={PostsListing} />,
      position: 110,
    },
  ];
}

const { switchItems } = new RouterPlugin();

export const config = {
  'Component/Router/Component': {
    'member-property': {
      SWITCH_ITEMS_TYPE: switchItems,
    },
  },
};

export default config;
