/* eslint-disable */
// import PropTypes from 'prop-types';
import { SubscriptionActionsComponent as SourceSubscriptionActionsComponent } from "@hoeks/subscription/src/component/SubscriptionActions/SubscriptionActions.component"
import './SubscriptionActions.override.style';
import Field from 'Component/Field';
import ProductPrice from 'Component/ProductPrice';
import { SELECT_TYPE, CHECKBOX_TYPE } from 'Component/Field/Field.config';
import { BUNDLE, CONFIGURABLE, GROUPED } from 'Util/Product';

/** @namespace Hoeks/Subscription/Component/SubscriptionActions/Component/SubscriptionActionsComponent */
export class SubscriptionActionsComponent extends SourceSubscriptionActionsComponent {
    renderOneTimePayment() {
        if (this.props.subscription_type != "either") return null;
        const { acceptedOneTimePayment, onOneTimePaymentAccept, product_type, offerCount } = this.props;

        return (
            <div block="SubscriptionActions" elem="OneTimePayment">
                <Field
                    id="OneTimePayment_Toggle"
                    name="OneTimePayment_Toggle"
                    checked={acceptedOneTimePayment}
                    onChange={onOneTimePaymentAccept}
                    type="checkbox"
                />
                <p>{__('No subscription')}</p>
                {product_type == CONFIGURABLE ? <p block="SubscriptionActions" elem="FromPrice">{__('From:')}</p> : <></>}
                <ProductPrice
                    isSchemaRequired
                    price={this.props.productPrice}
                    variantsCount={offerCount}
                    block="OneTimePayment"
                    elem="Price"
                />
            </div>
        );
    }

    renderSubscription() {
        const { onSubscriptionAccept, acceptedSubscription, onFieldSelectChanged, prepareSelectOptionsData, subscription_price_range, productPrice, product_type, subscriptionOptionsRef } = this.props;
        const { minimum_price: { discount: {amount_off = 0, percent_off = 0} = {}, regular_price: { value = 0 } = {} } = {} } = subscription_price_range || {};
        const showDiscount = amount_off || percent_off ? true : false;
        return (
            <div block="SubscriptionActions" elem="Subscription">
                <div block="SubscriptionActions" elem="SubscriptionTop">
                    <Field
                        id="Subscription_Toggle"
                        name="Subscription_Toggle"
                        checked={acceptedSubscription}
                        onChange={onSubscriptionAccept}
                        type="checkbox"
                    />
                    <p>{__('Buy coffee as subscription')}</p>
                    {product_type == CONFIGURABLE ? <p block="SubscriptionActions" elem="FromPrice">{__('From:')}</p> : <></>}
                    <ProductPrice
                        isSchemaRequired={false}
                        price={showDiscount ? subscription_price_range : productPrice}
                        block="SubscriptionActions"
                        elem="Price"
                    />
                </div>
                <div block="SubscriptionActions" elem="SubscriptionOption" style={{ marginTop: "-3px" }} ref={ subscriptionOptionsRef }>
                    <p>{__('Per cycle')}</p>
                    <div block="SubscriptionActions" elem="SubscriptionIntervals">
                        <Field
                            type={SELECT_TYPE}
                            selectOptions={prepareSelectOptionsData()}
                            onChange={(selctedValue) => onFieldSelectChanged(selctedValue)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default SubscriptionActionsComponent;
