/* eslint-disable */
// import PropTypes from 'prop-types';
import { SubscriptionCartItemComponent as SourceSubscriptionCartItemComponent } from '@hoeks/subscription/src/component/SubscriptionCartItem/SubscriptionCartItem.component';

import './SubscriptionCartItem.override.style';

/** @namespace Hoeks/Subscription/Component/SubscriptionCartItem/Component/SubscriptionCartItemComponent */
export class SubscriptionCartItemComponent extends SourceSubscriptionCartItemComponent {

}

export default SubscriptionCartItemComponent;
