/* eslint-disable */
import './PriceSlider.style';

/** @namespace Component/PriceSlider/Component */
export class PriceSlider extends PureComponent {
    render() {
        const { maxPrice, minVal, maxVal, onMinSliderChange, onMaxSliderChange, isMinTop, isMaxTop } = this.props;

        if (!maxPrice) {
            return null;
        }

        return (
            <div
                block="PriceSlider"
                elem="Main"
            >
                <div
                    block="PriceSlider"
                    elem="TitleContainer"
                >
                    <span
                        block="PriceSlider"
                        elem="Heading"
                        mix={ { block: 'ExpandableContent', elem: 'Heading' } }
                    >
                            { __('Price') }
                    </span>
                </div>
                <div
                    block="PriceSlider"
                    elem="TextContainer"
                >
                    <span
                        block="PriceSlider"
                        elem="MinVal"
                    >
                        { minVal }
                    </span>
                    <span
                        block="PriceSlider"
                        elem="ValSeparator"
                    >
                        -
                    </span>
                    <span
                        block="PriceSlider"
                        elem="MaxVal"
                    >
                        { maxVal }
                    </span>
                </div>
                <div
                    block="PriceSlider"
                    elem="SliderContainer"
                >
                    <input
                        block="PriceSlider"
                        elem="MinSlider"
                        mods={ { isTop: isMinTop } }
                        type="range"
                        name="min"
                        min={ 0 }
                        max={ maxPrice }
                        value={ minVal }
                        onChange={ onMinSliderChange }
                    />
                    <input
                        block="PriceSlider"
                        elem="MaxSlider"
                        mods={ { isTop: isMaxTop } }
                        type="range"
                        name="max"
                        min={ 0 }
                        max={ maxPrice }
                        value={ maxVal }
                        onChange={ onMaxSliderChange }
                    />
                </div>
            </div>
        );
    }
}

export default PriceSlider;