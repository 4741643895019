/* eslint-disable */

import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';

export class FieldContainer extends SourceFieldContainer {
    static defaultProps = {
        min: 1,
        // max: 99,
        checked: false,
        value: null,
        onChange: () => {},
        onFocus: () => {},
        onBlur: () => {},
        onClick: () => {},
        onKeyPress: () => {},
        formRef: () => {},
        isControlled: false,
        validation: [],
        message: '',
        customValidationStatus: null,
        id: '',
        formRefMap: {}
    };
}

export default FieldContainer;