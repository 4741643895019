/* eslint-disable */
import { FieldSelectContainer as SourceFieldSelectContainer } from 'SourceComponent/FieldSelect/FieldSelect.container';

/** @namespace HoeksPwa/Component/FieldSelect/Container/FieldSelectContainer */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    sortSelectOptions() {
        const { selectOptions } = this.props;

        // /**
        //  * Trim all null label values, sort alphabetically
        //  */
        // const sortedOptions = selectOptions.reduce(
        //     (acc, a) => (a.label ? [...acc, a] : acc), []
        // ).sort((a, b) => {
        //     const textA = a.label.toUpperCase();
        //     const textB = b.label.toUpperCase();
        //     // eslint-disable-next-line no-nested-ternary
        //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        // });

        return selectOptions;
    }
}

export default FieldSelectContainer;
