/* eslint-disable */

import {MIN_PASSWORD_LENGTH, validateEmail, validateEmails, isNotEmpty, validatePasswordMatch} from 'HoeksComponent/Form/Form.config';

/** @namespace Component/Form/Config/validateTelephone */
export const validateTelephone = ({ value }) => value.length > 0 && (value.match(/^(?:[0-9-] ?){7,13}[0-9]$/) || value.match(/^\+(?:[0-9-] ?){6,14}[0-9]$/));


export const validatePassword = ({ value }) => {
    const isMatchingCharactersLimit = value.length >= MIN_PASSWORD_LENGTH;

    if (isMatchingCharactersLimit) {
        return true;
    } else {
        return false;
    }
};

/** @namespace Component/Form/Config */
export const formConfig = () => ({
    email: {
        validate: validateEmail,
        message: __('Email is invalid.')
    },
    emails: {
        validate: validateEmails,
        message: __('Email addresses are not valid')
    },
    password: {
        validate: validatePassword,
        message: __('Your password should be 8 characters long.')
    },
    telephone: {
        validate: validateTelephone,
        message: __('Phone number is invalid!')
    },
    notEmpty: {
        validate: isNotEmpty,
        message: __('This field is required!')
    },
    password_match: {
        validate: validatePasswordMatch,
        message: __('Password does not match.')
    }
});

export default formConfig();