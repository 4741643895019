/* eslint-disable */
import SourceClerkSearch from '@hoeks/clerk/src/component/ClerkSearch/ClerkSearch.component';
export * from '@hoeks/clerk/src/component/ClerkSearch/ClerkSearch.component';

import Loader from 'Component/Loader';

// import new extended styles file
import './ClerkSearch.override.style.scss';

export class ClerkSearch extends SourceClerkSearch {
    renderContent() {
        const {
            isOpen,
            result,
            areDetailsLoaded,
            closeResults
        } = this.props;

        if (!isOpen || !result) {
            return null;
        }

        return (
            <>
                {isOpen ? <div className="ClerkOverlay-Background" onClick={closeResults} /> : null}
                <div block="ClerkSearch" elem="Results">
                    <Loader isLoading={!areDetailsLoaded} />
                        <div block="ClerkSearch" elem="ResultColumn" mods={{ type: 'leftColumn' }}>
                            {this.renderResults()}
                        </div>
                        <div block="ClerkSearch" elem="ResultColumn" mods={{ type: 'rightColumn' }}>
                            {this.renderSuggestions()}
                            {this.renderCategories()}
                        </div>
                        <div block="ClerkSearch" elem="ResultColumn" mods={{ type: 'bottom' }}>
                            {this.renderMoreResults()}
                        </div>
                </div>
            </>
        );
    }
}

export default ClerkSearch;
