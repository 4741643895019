// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { appendWithStoreCode } from 'Util/Url';
import history from 'Util/History';
import './ClerkSearch.style';
import ClerkSearchItem from '../ClerkSearchItem';
import ClickOutside from 'Component/ClickOutside';
import Link from 'Component/Link';
import Loader from 'Component/Loader';

/** @namespace Hoeks/Clerk/Component/ClerkSearch/Component/ClerkSearchComponent */
export class ClerkSearchComponent extends PureComponent {
    static propTypes = {
    };

    renderContent() {
        const { isOpen, result, areDetailsLoaded } = this.props;
        if (!isOpen || !result) return null;
        return (
            <div block="ClerkSearch" elem="Results">
                <Loader isLoading={!areDetailsLoaded} />
                <div block="ClerkSearch" elem="ResultColumn" mods={{ type: 'leftColumn' }}>
                    {this.renderResults()}
                </div>
                {this.renderRightColumn()}
                <div block="ClerkSearch" elem="ResultColumn" mods={{ type: 'bottom' }}>
                    {this.renderMoreResults()}
                </div>
            </div>
        );
    }

    renderRightColumn() {
        const { suggestionsLimit, categoriesLimit } = this.props;

        if (!suggestionsLimit && !categoriesLimit) return;

        return (
            <div block="ClerkSearch" elem="ResultColumn" mods={{ type: 'rightColumn' }}>
                {suggestionsLimit ? this.renderSuggestions() : <></>}
                {categoriesLimit ? this.renderCategories() : <></>}
            </div>
        );
    }

    renderResultsFor() {
        const { query, areDetailsLoaded } = this.props;

        if (!query || !areDetailsLoaded) {
            return null;
        }

        return (
            <p>{ __('Showing results for "%s"', query) }</p>
        );
    }

    renderResults() {
        const { result, areDetailsLoaded } = this.props;
        return (
            <div block="ClerkSearch" elem="ResultContainer" mods={{ type: 'products' }}>
                <h5 block="ClerkSearch" elem="ResultHeading">
                    {__('Results')}
                </h5>
                { this.renderResultsFor() }
                <ul block="ClerkSearch" elem="ProductList">
                    {result.length ? result.map(this.renderSearchItem) : areDetailsLoaded && __('No results')}
                </ul>
            </div>
        );
    }

    renderSuggestions() {
        const { suggestions = [], areDetailsLoaded } = this.props;
        return (
            <div block="ClerkSearch" elem="ResultContainer" mods={{ type: 'suggestions' }}>
                <h5 block="ClerkSearch" elem="ResultHeading">
                    {__('Search Suggestions')}
                </h5>
                <ul block="ClerkSearch" elem="SuggestionList">
                    {suggestions.length ? suggestions.map(this.renderSuggestion) : areDetailsLoaded && __('No results')}
                </ul>
            </div>
        );
    }
    renderSuggestion = (key) => {
        const { goToSearchPage } = this.props;
        const goToSearch = () => goToSearchPage(key);

        return (
            <li block="ClerkSearch" elem="SuggestionItem" key={key}>
                <a block="ClerkSearch" elem="SuggestionItemLink" onClick={goToSearch}>
                    {key}
                </a>
            </li>
        );

    }
    renderCategory({ name, id, url }) {
        return (
            <li block="ClerkSearch" elem="CategoryItem" key={ id }>
                <Link block="ClerkSearch" elem="CategoryItemLink" to={ url }>
                    { name }
                </Link>
            </li>
        );

    }

    renderCategories() {
        const { categories = [], areDetailsLoaded } = this.props;
        return (
            <div block="ClerkSearch" elem="ResultContainer" mods={ { type: 'categories' } }>
                <h5 block="ClerkSearch" elem="ResultHeading">
                    { __('Categories') }
                </h5>
                <ul block="ClerkSearch" elem="CategoryList">
                    {categories.length ? categories.map(this.renderCategory) : areDetailsLoaded && __('No results')}
                </ul>
            </div>
        );
    }

    renderMoreResults() {
        const { searchCriteria, count, areDetailsLoaded, goToSearchPage } = this.props;
        const goToSearch = () => goToSearchPage(searchCriteria);
        if (!areDetailsLoaded || !count) return null;
        return (
            <div block="ClerkSearch" elem="ResultContainer" mods={{ type: 'moreResults' }}>
                <a block="ClerkSearch" elem="MoreResults" onClick={goToSearch}>
                    {__('See more results matching')} <span block="ClerkSearch" elem="MoreResultTerm">"{searchCriteria}"</span> <span block="ClerkSearch" elem="MoreResultNumber">({count})</span>
                </a>
            </div>
        );
    }


    renderSearchItem = (product, i) => {
        const { closeResults, thumbnailItems = [] } = this.props;
        return (
            <ClerkSearchItem
                product={ product }
                key={ i }
                onClick={ closeResults }
                thumbnailItems={ thumbnailItems }
            />
        );
    }

    renderInput() {
        const { searchCriteria, onFocus, handleChange, onSearchEnterPress } = this.props;
        return (
            <div block="ClerkSearch" elem="InputWrapper">
                <input
                    id="search-field"
                    block="ClerkSearch"
                    elem="Input"
                    onChange={ handleChange }
                    onKeyDown={ onSearchEnterPress }
                    value={ searchCriteria }
                    mods={ { isActive: true } }
                    autoComplete="off"
                    aria-label={ __('Search') }
                    onFocus={ onFocus }
                />

                <span block="ClerkSearch" elem="Input-Icon"></span>
            </div>
        );
    }

    render() {
        const { device, isVisible, overlay, closeResults } = this.props;

        return (
            <ClickOutside onClick={closeResults}>
                <div block="ClerkSearch" mods={{ mobileActive: device.isMobile && isVisible && overlay }}>
                    { this.renderInput() }
                    { this.renderContent() }
                </div>
            </ClickOutside>
        );
    }
}

export default ClerkSearchComponent;
