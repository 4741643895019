import { CategoryPagination as SourceCategoryPagination } from 'SourceComponent/CategoryPagination/CategoryPagination.component';

import './CategoryPagination.override.style';

export * from 'SourceComponent/CategoryPagination/CategoryPagination.component';

/** @namespace SourceComponent/CategoryPagination/Component */
export class CategoryPagination extends SourceCategoryPagination {

}

export default CategoryPagination;
