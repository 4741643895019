/* eslint-disable */

import { Field } from 'Util/Query';

const addConfig = (args, callback, instance) => {
    return [
        ...callback.apply(instance, args),
        new Field('manage_subscription_interval')
            .addFieldList([
                'key',
                'interval_type',
                'no_of_interval',
                'interval_label'
            ]),
        'subscription_description_url'
        // new Field('free_shipping_subscription')
    ]
}

export default {
    'Query/Config': {
        'member-function': {
            '_getStoreConfigFields': addConfig
        }
    }
}