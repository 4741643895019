/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */

import { Field } from 'Util/Query';

/** @namespace ScandiPWA/Blog/Query */
export class BlogPostQuery {
  getQuery(options) {
    const post = this.postField(options);

    const { id = 'hello-world' } = options;

    return new Field('blogPost')
      .addArgument('id', 'String', id)
      .addFieldList(post)
      .setAlias('post');
  }

  postField(options) {
    const {
      getBasicDetails = true,
      getDescription,
      getMedia,
      getRelated,
    } = options;

    const fields = [];
    const author = this.authorField();
    const relatedPosts = this.relatedPostsField();

    if (getBasicDetails) {
      fields.push(
        'post_id',
        'identifier',
        'title',
        'publish_time',
        ...this.metaFields()
      );
    }

    if (getDescription) {
      fields.push('short_content', author, 'content');
    }

    if (getMedia) {
      fields.push('og_image', 'first_image', 'media_gallery');
    }

    if (getRelated) {
      fields.push(relatedPosts, 'related_products');
    }

    return fields;
  }

  authorField() {
    return new Field('author').addField('name');
  }

  relatedPostsField() {
    return new Field('related_posts').addFieldList([
      'post_id',
      'identifier',
      'title',
      'publish_time',
      'first_image',
    ]);
  }

  metaFields() {
    return ['meta_title', 'meta_description', 'meta_keywords'];
  }
}

export default new BlogPostQuery();
