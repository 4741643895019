/* eslint-disable */
const getAddressFields = (args, callback) => {
    return [
        ...callback(...args),
        'ean'
    ]
}

export default {
    'Query/MyAccount': {
        'member-function': {
            _getAddressFields: getAddressFields
        }
    }
}