import React, { PureComponent } from 'react';

import ProductLinks from 'Component/ProductLinks';
import { RELATED } from 'Store/LinkedProducts/LinkedProducts.reducer';

import './BlogRelatedProducts.style';

/** @namespace ScandiPWA/Blog/Component/BlogRelatedProducts/Component */
export class BlogRelatedProducts extends PureComponent {
    render() {
        return (
            <div block="BlogRelatedProducts" elem="Wrapper">
                <ProductLinks
                  linkType={ RELATED }
                  title={ __('Related Products') }
                />
            </div>
        );
    }
}

export default BlogRelatedProducts;
