import { Footer as SourceFooter } from 'SourceComponent/Footer/Footer.component';

import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import { NEWSLETTER_COLUMN } from './Footer.config';

import './Footer.override.style';

export * from 'SourceComponent/Footer/Footer.component';

export class Footer extends SourceFooter {
    renderCmsBlockWrapper() {
        const { footer_content: { footer_cms } = {} } = window.contentConfiguration;

        return (
            <div
              block="Footer"
              elem="CmsBlockWrapper"
              mix={ { block: 'Footer', elem: 'Content' } }
            >
                <div
                  block="Footer"
                  elem="Columns"
                  mix={ { block: 'ContentWrapper' } }
                >
                    <CmsBlock identifier={ footer_cms } blockType="cmsBlock" />
                    {/* { this.renderColumn({
                        ...NEWSLETTER_COLUMN,
                        mods: { isNewsletter: true }
                    }) } */}
                </div>
            </div>
        );
    }

    renderCopyrightContent() {
        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'CopyrightContent' } }
              label=""
            >
                <div
                  block="Footer"
                  elem="CopyrightContentWrapperInner"
                >
                    <CmsBlock identifier="cms-footer-copyright" blockType="copyright" />
                    <CmsBlock identifier="cms-footer-icons" blockType="icons" />
                    <CmsBlock identifier="cms-footer-certificates-kifaru" blockType="certificates" />
                </div>
            </ContentWrapper>
        );
    }

    render() {
        // const { isVisibleOnMobile, device } = this.props;

        // if (!isVisibleOnMobile && device.isMobile) {
        //     return null;
        // }

        // if (isVisibleOnMobile && !device.isMobile) {
        //     return null;
        // }

        return (
            <footer block="Footer" aria-label="Footer">
                { this.renderContent() }
                { this.renderCopyrightContent() }
            </footer>
        );
    }
}

export default Footer;
