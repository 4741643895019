/* eslint-disable */
import { useEffect } from 'react';
import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';
import BrowserDatabase from 'Util/BrowserDatabase';
import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import { RECENTLY_VIEWED_PRODUCTS } from 'Component/RecentlyViewedWidget/RecentlyViewedWidget.config';

const DID_DELETE_RECENTLY_VIEWED_PRODUCTS = 'DID_DELETE_RECENTLY_VIEWED_PRODUCTS';
const DID_DELETE_CART_TOTALS = 'DID_DELETE_CART_TOTALS';
const ONE_YEAR_IN_SECONDS = 31622400;

const sizeOf = (obj) => {
    var bytes = 0;

    const internalSizeOf = (obj) => {
        if(obj !== null && obj !== undefined) {
            switch(typeof obj) {
            case 'number':
                bytes += 8;
                break;
            case 'string':
                bytes += obj.length * 2;
                break;
            case 'boolean':
                bytes += 4;
                break;
            case 'object':
                var objClass = Object.prototype.toString.call(obj).slice(8, -1);
                if(objClass === 'Object' || objClass === 'Array') {
                    for(var key in obj) {
                        if(!obj.hasOwnProperty(key)) continue;
                        internalSizeOf(obj[key]);
                    }
                } else bytes += obj.toString().length * 2;
                break;
            }
        }
        return bytes;
    }

    return internalSizeOf(obj);
};

const StorageFix = (props) => {
    useEffect(() => {
        const recentlyViewedProducts = BrowserDatabase.getItem(RECENTLY_VIEWED_PRODUCTS);
        const cartTotals = BrowserDatabase.getItem(CART_TOTALS);
        // 1572864 is 1.5 MiB in bytes
        if (sizeOf(recentlyViewedProducts) >= 1572864 && !BrowserDatabase.getItem(DID_DELETE_RECENTLY_VIEWED_PRODUCTS)) {
            BrowserDatabase.deleteItem(RECENTLY_VIEWED_PRODUCTS);
        }
        if (sizeOf(cartTotals) >= 1572864 && !BrowserDatabase.getItem(DID_DELETE_CART_TOTALS)) {
            BrowserDatabase.deleteItem(CART_TOTALS);
        }
        BrowserDatabase.setItem(true, DID_DELETE_RECENTLY_VIEWED_PRODUCTS, ONE_YEAR_IN_SECONDS);
        BrowserDatabase.setItem(true, DID_DELETE_CART_TOTALS, ONE_YEAR_IN_SECONDS);
    });

    return null;
}

const removeLargeStorage = (member) => {
    return [
        ...member,
        {
            component: <StorageFix />,
            position: 0
        }
    ]
}

const recentlyViewedProductsInitialState = (args, callback, instance) => {
    const result = callback.apply(instance, args);
    // 1572864 is 1.5 MiB in bytes
    if (sizeOf(result) >= 1572864) {
        return [];
    }
    return result;
}

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: removeLargeStorage
        }
    },
    'Store/RecentlyViewedProducts/Reducer/getInitialState': {
        'function': recentlyViewedProductsInitialState
    }
}