/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable prefer-template */
import { RecentComponent as HoeksRecent } from '@hoeks/blog-extension-pwa/src/component/Recent/Recent.component';

import Link from 'Component/Link';
import Image from 'Component/Image';

import './Recent.override.style';

export * from '@hoeks/blog-extension-pwa/src/component/Recent/Recent.component';

/** @namespace HoeksPwa/BlogExtensionPwa/Component/Recent/Component */
export class RecentComponent extends HoeksRecent {
    renderPost(post, i) {
        const {
            postId,
            postUrl, postTitle,
            featuredImage,
            featuredImageAlt
        } = post;

        const classes = 'BlogRecentPosts-PostCard BlogRecentPosts-Post-' + postId;
        const url = postUrl.substring(postUrl.indexOf('/blog'));

        return (
            <div
            className={classes}
            key={ postId }
            >
                <Link
                to={ postUrl }
                title={ __('View %s', postTitle) }
                mix={ { block: 'BlogRecentPosts', elem: 'ImageWrapper' } }
                >
                    <Image
                        src={ featuredImage }
                        ratio="16x9"
                        isPlaceholder={ !featuredImage }
                        alt={ featuredImageAlt }
                    />
                </Link>
                <div
                block="BlogRecentPosts"
                elem="Details"
                >
                    <Link to={ postUrl } title={ __('View %s', postTitle) }>
                        <h3 block="BlogRecentPosts" elem="PostTitle">
                            { postTitle }
                        </h3>
                    </Link>
                </div>
            </div>
        );
    }

    render() {
        const { postData } = this.props;

        return (
            <div block="BlogRecentPosts" elem="OuterWrapper" mix={{ block: 'Recent', elem: 'Widget' }}>
                <div block="BlogRecentPosts" elem="Header">
                    <div block="BlogRecentPosts" elem="Headding">
                        {__('Explore')}
                    </div>
                    <div block="BlogRecentPosts" elem="Link">
                        <Link to="/blog">
                            {__('All Posts')}
                            <span block="BlogRecentPosts" elem="LinkArrow" />
                        </Link>
                    </div>
                </div>
                <div block="BlogRecentPosts" elem="Wrapper">
                    {postData.map((post, i) => this.renderPost(post, i))}
                </div>
            </div>
        );
    }
}

export default RecentComponent;
