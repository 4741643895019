import Recent from "../component/Recent";
import RecentPlaceholder from "../component/RecentPlaceholder";
export const BLOG_EXTENSION_PWA_TYPE = 'BlogExtensionPwa';
const addBlogExtensionWidget = (member, instance) => {
    return {
        ...member,
        [BLOG_EXTENSION_PWA_TYPE]: {
            component: Recent,
            fallback: () => <RecentPlaceholder />
        }
    }
}

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': addBlogExtensionWidget
        }
    },
}