import { ClerkContainer as SourceClerkContainer, mapStateToProps, mapDispatchToProps } from '@hoeks/clerk/src/component/Clerk/Clerk.container';
import { connect } from 'react-redux';

//import './Clerk.override.style';
import { LINK_TYPE } from '@hoeks/clerk/src/component/Clerk/Clerk.config';

export * from '@hoeks/clerk/src/component/Clerk/Clerk.container';

/** @namespace HoeksPwa/Component/Clerk/Container */
export class ClerkContainer extends SourceClerkContainer {
    state = {
        areDetailsLoaded: false,
        linkedProducts: { [LINK_TYPE]: {total_count: 0, items: []} },
        linkType: LINK_TYPE,
        numberOfProductsToDisplay: 8
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClerkContainer);
