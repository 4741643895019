/* eslint-disable */
import Field from 'Component/Field';

const componentDidMount = (args, callback, instance) => {
    const result = callback.apply(instance, args);

    const {
        areDetailsLoaded,
    } = instance.props;

    const qtyIncrements = instance.getQtyIncrements();
    const qtyIncrementsEnabled = instance.getQtyIncrementsEnabled();

    if (qtyIncrementsEnabled && areDetailsLoaded && qtyIncrements > 1) {
        instance.setState(state => ({
            ...state,
            quantity: qtyIncrements
        }));
    }

    return result;
}

const componentDidUpdate = (args, callback, instance) => {
    const result = callback.apply(instance, args);

    const [ prevProps ] = args;

    const {
        areDetailsLoaded
    } = instance.props;

    const {
        areDetailsLoaded: prevAreDetailsLoaded
    } = prevProps;

    const qtyIncrements = instance.getQtyIncrements();
    const prevQtyIncrements = instance.getQtyIncrements(prevProps);
    const qtyIncrementsEnabled = instance.getQtyIncrementsEnabled();
    const prevQtyIncrementsEnabled = instance.getQtyIncrementsEnabled(prevProps);

    if (qtyIncrementsEnabled && areDetailsLoaded && (areDetailsLoaded !== prevAreDetailsLoaded || qtyIncrements !== prevQtyIncrements)) {
        instance.setState(state => ({
            ...state,
            quantity: qtyIncrements
        }));
    } else if (!qtyIncrementsEnabled && prevQtyIncrementsEnabled) {
        instance.setState(state => ({
            ...state,
            quantity: 1
        }));
    }

    return result;
}

const renderQuantityInput = (args, callback, instance) => {
    const result = callback.apply(instance, args);

    const {
        getQtyIncrements,
        getQtyIncrementsEnabled
    } = instance.props;

    if (!result || !React.isValidElement(result) || !getQtyIncrementsEnabled()) {
        return result;
    }

    const qtyIncrements = getQtyIncrements();

    if (result.type === Field) {
        return React.cloneElement(result, { increment: qtyIncrements });
    }

    return result;
}

const getQtyIncrements = (args, callback, instance) => {
    const [ props = false ] = args;

    const {
        configurableVariantIndex,
        productOrVariant: {
            qty_increments: variantQtyIncrements = 1
        } = {},
        product: {
            qty_increments: qtyIncrements = 1
        }
    } = props || instance.props;

    const finalQtyIncrements = configurableVariantIndex > -1 ? variantQtyIncrements : qtyIncrements;
    return finalQtyIncrements;
}

const getQtyIncrementsEnabled = (args, callback, instance) => {
    const [ props = false ] = args;

    const {
        configurableVariantIndex,
        productOrVariant: {
            qty_increments_enabled: variantQtyIncrementsEnabled = false
        } = {},
        product: {
            qty_increments_enabled: qtyIncrementsEnabled = false
        }
    } = props || instance.props;

    const finalQtyIncrementsEnabled = configurableVariantIndex > -1 ? variantQtyIncrementsEnabled : qtyIncrementsEnabled;
    return finalQtyIncrementsEnabled;
}

const containerFunctions = (originalMember, instance) => ({
    ...originalMember,
    getQtyIncrements: instance.getQtyIncrements.bind(instance),
    getQtyIncrementsEnabled: instance.getQtyIncrementsEnabled.bind(instance)
});

export default {
    'Component/ProductActions/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate,
            getQtyIncrements,
            getQtyIncrementsEnabled
        },
        'member-property': {
            containerFunctions
        }
    },
    'Component/ProductActions/Component': {
        'member-function': {
            renderQuantityInput
        }
    }
}