export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES_MENU';
export const UPDATE_CATEGORIES_TOP_LEVEL = 'UPDATE_CATEGORIES_TOP_LEVEL_MENU';

/**
 * 
 * @param {Object} categories Categories
 * @return {void}
 * @namespace Store/CategoryMenu/Action/updateCategories 
 */
export const updateCategories = categories => ({
    type: UPDATE_CATEGORIES,
    categories
});

/**
 * 
 * @param {Object} categories Categories
 * @return {void}
 * @namespace Store/CategoryMenu/Action/updateCategories 
 */
export const updateCategoriesTopLevel = category => ({
    type: UPDATE_CATEGORIES_TOP_LEVEL,
    category
});