// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import TextPlaceholder from 'Component/TextPlaceholder';
import './RecentPlaceholder.style';

/** @namespace Hoeks/BlogExtensionPwa/Component/RecentPlaceholder/Component/RecentPlaceholderComponent */
export class RecentPlaceholderComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        return (
            <div block="RecentPlaceholder">
                <TextPlaceholder></TextPlaceholder>
            </div>
        );
    }
}

export default RecentPlaceholderComponent;
