/* eslint-disable */
import Link from 'Component/Link';

import './ClerkSearchOriginalQuery.style';

/** @namespace Component/ClerkSearchOriginalQuery/Component */
export class ClerkSearchOriginalQuery extends PureComponent {
    render() {
        const { originalQuery, shownQuery } = this.props;

        if (!originalQuery) {
            return null;
        }

        if (originalQuery == shownQuery) {
            return null;
        }

        const search = originalQuery.trim().replace(/\s\s+/g, '%20');

        const to = {
            pathname: search,
            state: {}
        }

        return (
            <p
                block="ClerkSearchOriginalQuery"
                elem="OriginalQuery"
            >
                <Link to={ to }>{ __('Show results for "') }<strong>{ originalQuery }</strong>{ __('" instead.') }</Link>
            </p>
        );
    }
}

export default ClerkSearchOriginalQuery;