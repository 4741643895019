import { Field } from 'Util/Query';

/** @namespace Hoeks/ProductAlert/Query/ProductAlert/Query/ProductAlertQuery */
export class ProductAlertQuery {
    addEmailToProductAlert(email, productId, storeId, type = 'stock') {
        return new Field('hoeksProductAlert')
        .addArgument('email', 'String', email)
        .addArgument('product_id', 'String', productId)
        .addArgument('type', 'String', type)
        .addArgument('store', 'Int', storeId)
        .addField('status')
        .addField('message');
    }
}

export default new ProductAlertQuery();
