/* eslint-disable */

const renderTypeNumber = (args, callback, instance) => {
    const {
        value,
        handleChange,
        increment,
        min,
        max
    } = instance.props;
    const result = callback.apply(instance, args);

    if (!result || !React.isValidElement(result) || !increment) {
        return result;
    }

    if (result.props.children && React.Children.count(result.props.children)) {
        const updatedChildren = React.Children.map(result.props.children, (child, idx) => {
            if (!React.isValidElement(child) || child.type !== 'button') {
                return child;
            }

            if (idx === 1) {
                return React.cloneElement(child, {
                    disabled: (+value === max) || (+value + increment > max),
                    onClick: () => handleChange(+value + increment)
                });
            }
            if (idx === 2) {
                return React.cloneElement(child, {
                    disabled: (+value === min) || (+value - increment < min),
                    onClick: () => handleChange(+value - increment)
                });
            }
        });

        const updatedResult = React.cloneElement(result, {}, updatedChildren);

        return updatedResult;
    }

    return result;
}

export default {
    'Component/Field/Component': {
        'member-function': {
            renderTypeNumber
        }
    }
}