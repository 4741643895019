import Event, { EVENT_GTM_PURCHASE } from '../util/Event';

const getOrderData = async (args, callback, instance) => {
    const result = await callback(...args);

    const {
        paymentData: {
            order_id,
            totals
        } = {},
        cartTotals: {
            items = []
        } = {}
    } = result;

    // const later = (delay) => {
    //     return new Promise(function(resolve) {
    //         setTimeout(resolve, delay);
    //     });
    // }

    // await later(20000);
    // console.log(result);
    // console.log(items);
    // console.log(totals);

    Event.dispatch(
        EVENT_GTM_PURCHASE,
        { orderID: order_id, totals: { ...totals, items } }
    );

    return result;
};

export default {
    'Hoeks/Sparxpres/Route/SparxpresGateway/Container': {
        'member-function': {
            'getOrderData': getOrderData
        }
    }
};