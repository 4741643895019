import { EpayGateway as SourceEpayGateway } from '@hoeks/epay/src/route/EpayGateway/EpayGateway.component';

import './EpayGateway.override.style';

export * from '@hoeks/epay/src/route/EpayGateway/EpayGateway.component';

/** @namespace HoeksPwa/Route/EpayGateway/Component */
export class EpayGateway extends SourceEpayGateway {

}

export default EpayGateway;
