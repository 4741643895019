/* eslint-disable */
import { Field } from 'Util/Query';

/** @namespace Query/PrivateLabel */
export class PrivateLabelQuery {
    getQuery() {
        return new Field('privateLabel')
            .addFieldList(this._getPrivateLabelFieldList());
    }

    _getPrivateLabelFieldList() {
        return [
            'url',
            'has_private_label',
            'id'
        ];
    }
}

export default new PrivateLabelQuery();