/* eslint-disable */
import { Header as HoeksHeader } from 'HoeksComponent/Header/Header.component';

import NavigationTabs from 'Component/NavigationTabs';
import CmsBlock from 'Component/CmsBlock';
import CurrencySwitcher from 'Component/CurrencySwitcher';
import Link from 'Component/Link';
import Logo from 'Component/Logo';
import StoreSwitcher from 'Component/StoreSwitcher';
import CSS from 'Util/CSS';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';
import {
	CART_OVERLAY,
	CUSTOMER_WISHLIST
} from 'Component/Header/Header.config';

import './Header.override.style';

export * from 'HoeksComponent/Header/Header.component';

/** @namespace Component/Header/Component */
export class Header extends HoeksHeader {

	state = { scrolled: false };

	componentDidMount() {
		window.addEventListener('scroll', () => {
			const scrolled = (window.scrollY !== 0);
			this.setState({ scrolled });
		});
	}

	renderMap = {
		cancel: this.renderCancelButton.bind(this),
		back: this.renderBackButton.bind(this),
		close: this.renderCloseButton.bind(this),
		share: this.renderShareWishListButton.bind(this),
		title: this.renderTitle.bind(this),
		logo: this.renderLogo.bind(this),
		search: this.renderSearchField.bind(this),
		account: this.renderAccount.bind(this),
		minicart: this.renderMinicart.bind(this),
		compare: this.renderComparePageButton.bind(this),
		clear: this.renderClearButton.bind(this),
		edit: this.renderEditButton.bind(this),
		ok: this.renderOkButton.bind(this)
	};

	renderLogoImage() {
		const {
			header_logo_src,
			logo_alt,
			logo_height,
			logo_width
		} = this.props;

		// if no src defined from the backend, pass null in order to display placeholder
		// and prevent unnecessary load of corrupted resource
		// const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

		// NOTE(bjarne): I made their SVG logo be the fallback, so they can change it themselves later in the store admin if they want.
		const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : 'https://www.berrybean.dk/pub/media/hoeks-pwa/logo/kifaru-logo.svg';
		const logoAlt = header_logo_src ? logo_alt : __('Berry&Bean logo');
		const logoHeight = header_logo_src ? logo_height : 150;
		const logoWidth = header_logo_src ? logo_width : 150;

		CSS.setVariable(this.logoRef, 'header-logo-height', `${logoHeight}px`);
		CSS.setVariable(this.logoRef, 'header-logo-width', `${logoWidth}px`);

		return (
			<Logo
				src={logoSrc}
				alt={logoAlt}
				title={logoAlt}
			/>
		);
	}

	renderLogo(isVisible = false) {
		const { isLoading } = this.props;

		if (isLoading) {
			return null;
		}

		return (
			<Link
				to="/"
				aria-label={__('Berry&Bean logo')}
				aria-hidden={!isVisible}
				tabIndex={isVisible ? 0 : -1}
				block="Header"
				elem="LogoWrapper"
				mods={{ isVisible }}
				key="logo"
			>
				{this.renderLogoImage()}
			</Link>
		);
	}

	renderTopMenuInfo() {
		const { closeTopMenu } = this.props;

		return (
			<>
				<CmsBlock identifier="topmenuinfo_cms" blockType="TopMenuInfo" />
				<div onClick={() => closeTopMenu()} block="Header" elem="TopMenuInfoClose"></div>
			</>

		);
	}

	renderTopMenu() {
		// const { device } = this.props;
		// if (device.isMobile) {
		//     return null;
		// }

		return (
			<>
				<div block="Header" elem="TopMenu">
					<div block="Header" elem="TopMenuInfo">
						{this.renderTopMenuInfo()}
					</div>
					{/* <div block="Header" elem="Switcher">
                        <CurrencySwitcher />
                        <StoreSwitcher />
                    </div> */}
				</div>
				<NavigationTabs />
			</>
		);
	}

	render() {
		const { stateMap } = this;
		const {
			navigationState: { name, isHiddenOnMobile = false },
			isCheckout,
			device,
			topMenuIsClosed
		} = this.props;

		const {
			scrolled
		} = this.state

		if (!device.isMobile) {
			// hide edit button on desktop
			stateMap[CUSTOMER_WISHLIST].edit = false;
			stateMap[CUSTOMER_WISHLIST].share = false;
			stateMap[CART_OVERLAY].edit = false;
		}

		return (
			<section block="Header" elem="Wrapper" mods={{ name, isCheckout, scrolled, topMenuIsClosed }}>
				<header
					block="Header"
					mods={{ name, isHiddenOnMobile, isCheckout, scrolled }}
					mix={{ block: 'FixedElement', elem: 'Top' }}
					ref={this.logoRef}
				>
					{this.renderTopMenu()}
					<nav block="Header" elem="Nav">
						{this.renderNavigationState()}
					</nav>
					{this.renderMenu()}
				</header>
			</section>
		);
	}

}

export default Header;
