/* eslint-disable */
import { HOEKS_GOOGLE_MAP_MARKER } from '../component/GoogleMapMarkerWidget/GoogleMapMarkerWidget.config';
import { lazy } from 'react';
import Loader from 'Component/Loader';

export const GoogleMapMarkerWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-google-map-marker" */
    '../component/GoogleMapMarkerWidget'
));

const widgetFactoryComponentMemberProperties = {
    renderMap: (member, instance) => ({
        ...member,
        [HOEKS_GOOGLE_MAP_MARKER]: {
            component: GoogleMapMarkerWidget,
            fallback: (props) => <Loader isLoading={ true } { ...props } />
        }
    })
}

export default {
    'Component/WidgetFactory/Component': {
        'member-property': widgetFactoryComponentMemberProperties
    }
}