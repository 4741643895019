/* eslint-disable */
const componentDidMount = (args, callback, instance) => {
    const { serviceWorker: { controller } = {} } = navigator;
    if (!controller) {
        return;
    }
    return callback(...args);
}

export default {
    'Component/NewVersionPopup/Container': {
        'member-function': {
            componentDidMount: componentDidMount
        }
    }
}