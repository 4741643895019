import { ProductLinks as SourceProductLinks } from 'SourceComponent/ProductLinks/ProductLinks.component';

import './ProductLinks.override.style';

export * from 'SourceComponent/ProductLinks/ProductLinks.component';

/** @namespace HoeksPwa/Component/ProductLinks/Component */
export class ProductLinks extends SourceProductLinks {

}

export default ProductLinks;
