/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */

import { showNotification } from 'Store/Notification/Notification.action';
import { QueryDispatcher } from 'Util/Request';

import BlogPostsQuery from '../../query/BlogPosts.query';
import { postsException, updatePosts } from './Posts.action';

/**
 * Posts Dispatcher
 * @extends QueryDispatcher
 * @namespace ScandiPWA/Blog/Store/Posts/Dispatcher
 */
export class PostsDispatcher extends QueryDispatcher {
  __construct() {
    super.__construct('BlogPosts');
  }

  onSuccess(data, dispatch) {
    // console.log(data);
    const { posts } = data;
    dispatch(updatePosts(posts));
  }

  onError([{ message }], dispatch) {
    dispatch(postsException(message));
    dispatch(showNotification('error', 'Error fetching Posts!', message));
  }

  prepareRequest(options) {
    return BlogPostsQuery.getQuery(options);
  }
}

export default new PostsDispatcher();
