/* eslint-disable */
import { LinkedProductsDispatcher as SourceLinkedProductsDispatcher } from 'SourceStore/LinkedProducts/LinkedProducts.dispatcher';
import ProductListQuery from 'Query/ProductList.query';
export * from 'SourceStore/LinkedProducts/LinkedProducts.dispatcher';

/**
 * Linked Prodcts List Dispatcher
 * @class LinkedProductsDispatcher
 * @extends SourceLinkedProductsDispatcher
 * @namespace Store/LinkedProducts/Dispatcher
 */
export class LinkedProductsDispatcher extends SourceLinkedProductsDispatcher {
    /**
     * Prepare LinkedProducts query
     * @return {Query} ProductList query
     * @memberof LinkedProductsDispatcher
     * @param product_links
     */
    prepareRequest(product_links) {
        const relatedSKUs = product_links.reduce((links, link) => {
            const { linked_product_sku } = link;
            return [...links, `${ linked_product_sku.replace(/ /g, '%20') }`];
        }, []);

        return [
            ProductListQuery.getQuery({
                args: {
                    pageSize: relatedSKUs.length || 20,
                    filter: {
                        productsSkuArray: relatedSKUs
                    }
                },
                notRequireInfo: true
            })
        ];
    }
}

export default new LinkedProductsDispatcher();
