import { Breadcrumbs as SourceBreadcrumbs } from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';

import './Breadcrumbs.override.style';

export * from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';

/** @namespace SourceComponent/Breadcrumbs/Component */
export class Breadcrumbs extends SourceBreadcrumbs {
    // render() {
    //     return null;
    // }
}

export default Breadcrumbs;
