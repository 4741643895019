import { GET_SUBSCRIPTION_PROFILES, GET_SUBSCRIPTION, UPDATE_SUBSCRIPTION, UPDATE_IS_LOADING } from './SubscriptionProfile.action';

/** @namespace Store/SubscriptionProfile/Reducer/getInitialState */
export const getInitialState = () => ({
    subscriptionProfiles: {},
    subscription: {},
    isLoading: true
});

/** @namespace Store/SubscriptionProfile/Reducer */
export const SubscriptionProfileReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
        case GET_SUBSCRIPTION_PROFILES:
            const { subscriptionProfiles, status } = action;

            return {
                ...state,
                subscriptionProfiles,
                isLoading: status
            };
        
        case GET_SUBSCRIPTION:
            const { subscription, subscriptionStatus } = action

            return {
                ...state,
                subscription,
                isLoading: subscriptionStatus
            }

        case UPDATE_SUBSCRIPTION:
            const { newSubscription, data, updateStatus } = action;

            newSubscription.qty_subscribed = data.qty;
            newSubscription.billing_address_id = data.md_billing_address;
            newSubscription.shipping_address_id = data.md_shipping_address;
            newSubscription.billing_period_label = data.billing_label;
            
            return {
                ...state,
                data,
                subscription: newSubscription,
                isLoading: updateStatus
            }
        
        case UPDATE_IS_LOADING:
            const {loadingStatus} = action;

            return {
                ...state,
                isLoading: loadingStatus
            }
        default:
            return state;
    }
}

export default SubscriptionProfileReducer;