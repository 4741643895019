/* eslint-disable */
import ClerkSearch from '../component/ClerkSearch';
import ClerkProductList from '../component/ClerkProductList';
import ClerkSearchOriginalQuery from '../component/ClerkSearchOriginalQuery';

const aroundRenderSearchField = (args, callback, instance) => {
    return <ClerkSearch key="clerksearch"/>;
}
const aroundRenderSearchFieldOverlay = (args, callback, instance) => {
    const { activeMenuItemsStack } = instance.props;
    const isVisible = !activeMenuItemsStack.length;
    return <ClerkSearch isVisible={isVisible} overlay={true} />;
}
const aroundGetIsMatchingListFilter = (args, callback, instance) => {
    return true;
}
const aroundRenderCategoryProductList = (args, callback, instance) => {
    const {
        filter,
        search,
        selectedSort,
        selectedFilters,
        isMatchingListFilter,
        isCurrentCategoryLoaded,
        isMatchingInfoFilter
    } = instance.props;

    const { layout } = instance.state;

    if (!instance.displayProducts()) {
        return null;
    }

    return (
        <div block="CategoryPage" elem="ProductListWrapper">
            { instance.renderItemsCount(true) }
            <ClerkProductList
              filter={ filter }
              search={ search }
              sort={ selectedSort }
              selectedFilters={ selectedFilters }
              isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
              isMatchingListFilter={ isMatchingListFilter }
              isMatchingInfoFilter={ isMatchingInfoFilter }
              layout={ layout }
            />
        </div>
    );
}

const renderOriginalQuery = (args, callback, instance) => {
    const {
        history: {
            location: {
                state: {
                    originalQuery
                } = {}
            } = {}
        } = {},
        mix
    } = instance.props;

    if (!originalQuery) {
        return null;
    }

    const search = originalQuery.trim().replace(/\s\s+/g, '%20');

    return (
        <h5
            block="CategoryDetails"
            elem="OriginalQuery"
            mix={ {
                block: 'SearchPage',
                elem: 'OriginalQuery'
            } }
        >
            <Link to={ `/search/${search}` }>{ __('Show results for "%s" instead.', originalQuery) }</Link>
        </h5>
    );
}

const aroundRenderSearchHeading = (args, callback, instance) => {
    const result = callback.apply(instance, args);

    return (
        <>
            { result }
            <ClerkSearchOriginalQuery/>
        </>
    )
}

export default {
    'Component/Header/Component': {
        'member-function': {
            'renderSearchField': aroundRenderSearchField
        }
    },
    'Component/MenuOverlay/Component': {
        'member-function': {
            'renderSearchField': aroundRenderSearchFieldOverlay
        }
    },
    'Route/SearchPage/Component': {
        'member-function': {
            'renderCategoryProductList': aroundRenderCategoryProductList,
            'renderFilterOverlay': () => null,
            'renderSearchHeading': aroundRenderSearchHeading,
            renderOriginalQuery
        }
    },
    'Route/SearchPage/Container': {
        'member-function': {
            'getIsMatchingListFilter': aroundGetIsMatchingListFilter,
            'getIsMatchingInfoFilter': aroundGetIsMatchingListFilter
        }
    }
}