/* eslint-disable */
import { RADIO, PRICE_TYPE_PERCENT } from 'Component/ProductBundleItem/ProductBundleItem.config';
import Field from 'Component/Field';

class ProductBundleItemComponentPlugin {
    renderMap = (member, instance) => ({
        ...member,
        [RADIO]: this.renderRadioValues.bind(instance)
    })

    renderOptionRadioValue = (args, callback, instance) => {
        const [ item ] = args;

        const {
            selectedDropdownValue,
            setRadioValue,
            renderOptionLabel
        } = instance.props;

        if (!item.product) {
            return null;
        }

        const {
            id,
            label,
            price_type,
            quantity,
            finalOptionPrice,
            price,
            product: {
                price_range: {
                    minimum_price: {
                        final_price: {
                            currency
                        } = {}
                    } = {}
                } = {}
            } = {}
        } = item;

        const finalPrice = price_type === PRICE_TYPE_PERCENT ? price : finalOptionPrice;
        const priceLabel = !(price_type !== PRICE_TYPE_PERCENT && finalPrice <= 0) ? renderOptionLabel(price_type, finalPrice, currency) : '';
        const isRadioSelected = selectedDropdownValue === id;

        return (
            <div key={ id }>
                <Field
                    type="radio"
                    label={ instance.renderHeading(label, priceLabel, quantity) }
                    id={ `option-${ id }` }
                    name={ `option-${ id }` }
                    value={ id }
                    checked={ isRadioSelected }
                    onClick={ setRadioValue }
                />
            </div>
        );
    }

    renderRadioValues() {
        const { option: { required, options } } = this.props;

        return (
            <>
                { options.map(this.renderOptionRadioValue) }
                { this.renderRequired(required) }
            </>
        );
    }
}

class ProductBundleItemContainerPlugin {
    containerFunctions = (member, instance) => ({
        ...member,
        setRadioValue: this.setRadioValue.bind(instance)
    })

    setRadioValue(value) {
        const { selectedDropdownValue } = this.state;
        const newValue = parseInt(value.target.value);

        if (newValue === selectedDropdownValue) {
            return;
        }

        return this.setDropdownValue(newValue);
    }
}

const { renderMap, renderHeading, renderOptionRadioValue } = new ProductBundleItemComponentPlugin();
const { containerFunctions } = new ProductBundleItemContainerPlugin();

export default {
    'Component/ProductBundleItem/Component': {
        'member-property': {
            renderMap
        },
        'member-function': {
            renderOptionRadioValue
        }
    },
    'Component/ProductBundleItem/Container': {
        'member-property': {
            containerFunctions
        }
    }
}