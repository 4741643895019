// import PropTypes from 'prop-types';
import { __promisify__ } from 'glob';
import { PureComponent } from 'react';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import SubscriptionDetails from '../SubscriptionDetails';
import SubscriptionEdit from '../SubscriptionEdit';

import './MyAccountMySubscriptionProfiles.style';

/** @namespace Hoeks/Subscription/Component/MyAccountMySubscriptionProfiles/Component/MyAccountMySubscriptionProfilesComponent */
export class MyAccountMySubscriptionProfilesComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    renderNoSubscriptions() {
        return (
            <tr block="MyAccountMySubscriptionProfiles" elem="NoSubscriptions">
                <td colSpan={6}>{__('You have no subscriptions.')}</td>
            </tr>
        )
    }

    renderSubscriptionHeaderRow() {
        return (
            <tr>
                <th>{__('Subscription ID #')}</th>
                <th>{__('Profile Status')}</th>
                <th>{__('Created At')}</th>
                <th>{__('Next Occurrence Date')}</th>
                <th>{__('Payment Method')}</th>
                <th>{__('Action')}</th>
            </tr>
        )
    }

    renderSubscriptionStatus(subscriptionStatus) {
        switch (subscriptionStatus) {
            case 1:
                return __('Active');

            case 2:
                return __('Pause');

            case 3:
                return __('Expired');

            case 4:
                return __('Cancelled');

            case 5:
                return __('Suspended');

            case 6:
                return __('Failed');

            case 7:
                return __('Completed');

            case 8:
                return __('Renewed');

            default:
                return __('Pending');
        }
    }

    renderSubscriptionRows() {
        const { subscriptionProfiles, isLoading, onViewClicked } = this.props;

        if (subscriptionProfiles == undefined || subscriptionProfiles.length <= 0 || isLoading)
            return this.renderNoSubscriptions();

        return subscriptionProfiles.map(row => <tr block="MyAccountMySubscriptionTableRow">
            <td data-th={__('Subscription ID #')}>{row.profile_id}</td>
            <td data-th={__('Profile Status')}>{this.renderSubscriptionStatus(row.subscription_status)}</td>
            <td data-th={__('Created At')}>{row.created_at}</td>
            <td data-th={__('Next Occurrence Date')}>{row.subscription_status == 4 ? __('-') : row.next_occurrence_date}</td>
            <td data-th={__('Payment Method')}>{row.payment_title}</td>
            <td data-th={__('Action')}>
                <Link
                    to={`/my-account/my-subscriptions/view?id=${row.subscription_id}`}
                    onClick={() => onViewClicked(row)}
                >
                    <span>{__("View Subscription")}</span>
                </Link>
            </td>
        </tr>);
    }

    renderTable() {
        return (
            <table block="MyAccountMySubscriptionProfiles" elem="Table">
                <thead>
                    {this.renderSubscriptionHeaderRow()}
                </thead>
                <tbody>
                    {this.renderSubscriptionRows()}
                </tbody>
            </table>
        )
    }

    render() {
        const {
            currentPage,
            currentSubscriptionId,
            onEditClicked, changePage,
            onBackClicked,
            isLoading,
            onViewClicked,
            subscription
        } = this.props;
        var page;

        switch (currentPage) {
            case "view":
                page = <SubscriptionDetails changePage={changePage} currentSubscriptionId={currentSubscriptionId} onEditClicked={onEditClicked} onBackClicked={onBackClicked} />;
                break;

            case "edit":
                page = <SubscriptionEdit changePage={changePage} onBackClicked={onBackClicked} />
                break;

            case "list":
                page = this.renderTable();
                break;

            default:
                page = this.renderTable();
                break;
        }

        return (
            <div block="MyAccountMySubscriptionProfiles">
                <Loader isLoading={isLoading} />
                {page}
            </div>
        );
    }
}

export default MyAccountMySubscriptionProfilesComponent;
