// /* eslint-disable */
import { Field, Fragment } from 'Util/Query';

const addBaseFields = (args, callback) => {
  return [
    ...callback(...args),
    'is_subscription',
    'subscription_type',
    'discount_type',
    'discount_amount',
    getSubscriptionPriceRange()
  ];
}

const getSubscriptionPriceRange = () => {
  return new Field('subscription_price_range')
    .addFieldList([
      getMinimalPriceField()
    ])
}

const getMaximalPriceField = () => {
  return new Field('maximum_price')
  .addFieldList(getMinimalPriceFields());
}

const getMinimalPriceField = () => {
  return new Field('minimum_price')
    .addFieldList(getMinimalPriceFields());
}

const getMinimalPriceFields = () => {
  return [
    getDiscountField(),
    getFinalPriceField(),
    getFinalPriceExclTaxField(),
    getRegularPriceField(),
    getRegularPriceExclTaxField()
  ];
}

const getFinalPriceField = () => {
  return new Field('final_price')
      .addField('currency')
      .addField('value');
}

const getFinalPriceExclTaxField = () => {
  return new Field('final_price_excl_tax')
      .addField('currency')
      .addField('value');
}

const getRegularPriceField = () => {
  return new Field('regular_price')
      .addField('currency')
      .addField('value');
}

const getRegularPriceExclTaxField = () => {
  return new Field('regular_price_excl_tax')
      .addField('currency')
      .addField('value');
}

const getDiscountField = () => {
  return new Field('discount')
      .addField('amount_off')
      .addField('percent_off');
}

export default {
  'Query/ProductList': {
    'member-function': {
      _getProductInterfaceFields: addBaseFields
    }
  }
}