import { FieldSelect as SourceFieldSelect } from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

export * from 'SourceComponent/FieldSelect/FieldSelect.component';

/** @namespace HoeksPwa/Component/FieldSelect/Component */
export class FieldSelect extends SourceFieldSelect {

}

export default FieldSelect;
