/* eslint-disable */

import { MyAccountQuery as SourceMyAccountQuery } from 'HoeksQuery/MyAccount.query';
import { Field } from 'Util/Query';
export * from 'HoeksQuery/MyAccount.query';

export class MyAccountQuery extends SourceMyAccountQuery {
    _getAddressFields() {
        return [
            ...super._getAddressFields(),
            'company'
        ]
    }
}

export default new MyAccountQuery();