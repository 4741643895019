/* eslint-disable */
import { MenuOverlayComponent as HoeksMenuOverlay } from 'HoeksComponent/MenuOverlay/MenuOverlay.component';

import CmsBlock from 'Component/CmsBlock';

import './MenuOverlay.override.style';

export * from 'HoeksComponent/MenuOverlay/MenuOverlay.component';

export class MenuOverlayComponent extends HoeksMenuOverlay {
    componentDidMount() {
        window.removeEventListener('scroll', this.debouncedCloseMenu);
    }

    renderSocialMedia() {
        return (
            <div
              block="MenuOverlay"
              elem="SocialMediaWrapper"
            >
                <CmsBlock identifier="cms-menuoverlay-socialmedia" />
            </div>
        );
    }

    renderTopLevel() {
        return (
            <>
                { super.renderTopLevel() }
                { this.renderSocialMedia() }
            </>
        );
    }
}

export default MenuOverlayComponent;
