import Event, { EVENT_GTM_PRODUCT_DETAIL } from '../util/Event';

/** ProductPage */
const _gtmProductDetail = (instance) => {
    const { product, location: { pathname, search, state: { prevCategoryId } = {} }, configurableVariantIndex } = instance.props;

    if (product && product.price_range && product.attributes) {
        Event.dispatch(EVENT_GTM_PRODUCT_DETAIL, {
            product: { ...product, configurableVariantIndex, prevCategoryId },
            pathname: `${pathname}${search}`,
            list: prevCategoryId ? 'PLP' : null
        });
    }
};

const componentDidMount = (args, callback, instance) => {
    const { areDetailsLoaded, id, product: { id: productId } } = instance.props;

    if (areDetailsLoaded && id === productId) {
        _gtmProductDetail(instance);
    }

    return callback(...args);
};

const componentDidUpdate = (args, callback, instance) => {
    const [prevProps] = args;
    const shouldTriggerGtm = () => {
        const {
            areDetailsLoaded,
            id,
            product: { id: productId },
            location: { pathname },
            configurableVariantIndex
        } = instance.props;

        const {
            areDetailsLoaded: prevAreDetailsLoaded,
            location: { pathname: prevPathname },
            configurableVariantIndex: prevConfigurableVariantIndex
        } = prevProps;

        return areDetailsLoaded && id === productId && (
            (areDetailsLoaded !== prevAreDetailsLoaded)
            || (pathname !== prevPathname)
            || (configurableVariantIndex < 0 && prevConfigurableVariantIndex >= 0)
        );
    };

    const shouldTriggerGtmVariant = () => {
        const {
            areDetailsLoaded,
            id,
            product: { id: productId },
            configurableVariantIndex
        } = instance.props;

        const {
            configurableVariantIndex: prevConfigurableVariantIndex
        } = prevProps;

        return (
            areDetailsLoaded &&
            configurableVariantIndex > -1 &&
            configurableVariantIndex !== prevConfigurableVariantIndex &&
            id === productId
        )
    }

    if (shouldTriggerGtm()) {
        _gtmProductDetail(instance);
        return callback(...args);
    }

    if (shouldTriggerGtmVariant()) {
        _gtmProductDetail(instance);
        return callback(...args);
    }

    return callback(...args);
};

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            'componentDidMount': componentDidMount,
            'componentDidUpdate': componentDidUpdate
        }
    }
};
