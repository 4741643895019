/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */

import { connect } from 'react-redux';

import LinkedProductsDispatcher from 'Store/LinkedProducts/LinkedProducts.dispatcher';
import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';

import { PostsDetailsDispatcher } from '../../store/PostsDetails';
import { CategoriesDispatcher } from '../../store/Categories';
import PostsDetails from './PostsDetails.component';

export const BreadcrumbsDispatcher = import(
  /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
  'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

/** @namespace ScandiPWA/Blog/Route/PostsDetails/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
  post: state.PostsDetailsReducer.post,
});

/** @namespace ScandiPWA/Blog/Route/PostsDetails/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
  requestCategories: (options) =>
    CategoriesDispatcher.handleData(dispatch, options),
  updateBreadcrumbs: (breadcrumbs) =>
    BreadcrumbsDispatcher.then(({ default: dispatcher }) =>
      dispatcher.update(breadcrumbs, dispatch)
    ),
  requestPosts: (options) => {
    PostsDetailsDispatcher.handleData(dispatch, options);
    LinkedProductsDispatcher.clearLinkedProducts(dispatch);
  },
  updateMeta: (meta) => dispatch(updateMeta(meta)),
  setHeaderState: (stateName) =>
    dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostsDetails);
