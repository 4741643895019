export * from 'SourceComponent/Footer/Footer.config';

export const RENDER_NEWSLETTER = 'render_newsletter';

export const NEWSLETTER_COLUMN = {
    title: __('Sign up for our newsletter'),
    items: [
        {
            render: RENDER_NEWSLETTER
        }
    ]
};
