// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import SubscriptionEdit from './SubscriptionEdit.component';

export const SubscriptionProfileDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/SubscriptionProfile/SubscriptionProfile.dispatcher'
);

/** @namespace Hoeks/Subscription/Component/SubscriptionEdit/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    subscriptionIntervals: state.ConfigReducer.manage_subscription_interval,
    subscription: state.SubscriptionProfileReducer.subscription.subscription,
    addresses: state.MyAccountReducer.customer.addresses,
    isLoading: state.SubscriptionProfileReducer.isLoading
});

/** @namespace Hoeks/Subscription/Component/SubscriptionEdit/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    getSubscriptionData: (subscription_id) => SubscriptionProfileDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getSubscriptionData(_dispatch, subscription_id)
    ),
    updateSubscription: (subscription, data) => SubscriptionProfileDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateSubscription(_dispatch, subscription, data)
    ),
    updateIsLoading: (loadingStatus) => SubscriptionProfileDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateIsLoading(_dispatch, loadingStatus)
    )
});

/** @namespace Hoeks/Subscription/Component/SubscriptionEdit/Container/SubscriptionEditContainer */
export class SubscriptionEditContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    state = {
        quantity: 0,
        billingPeriod: '',
        billingLabel: '',
        billingAddressId: 0,
        shippingAddressId: 0
    }

    containerFunctions = {
        // getData: this.getData.bind(this)
        onQuantityChanged: this.onQuantityChanged.bind(this),
        onBillingPeriodChanged: this.onBillingPeriodChanged.bind(this),
        onBillingAddressChanged: this.onBillingAddressChanged.bind(this),
        onShippingAddressChanged: this.onShippingAddressChanged.bind(this),
        onUpdateClicked: this.onUpdateClicked.bind(this),
        prepareBillingPeriodSelectOptions: this.prepareBillingPeriodSelectOptions.bind(this),
        prepareAddressSelectOptions: this.prepareAddressSelectOptions.bind(this),
        getPrice: this.getPrice.bind(this)
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    componentDidMount() {
        const { getSubscriptionData, subscription, updateIsLoading } = this.props;
        if (subscription == undefined) {
            updateIsLoading(true);
            getSubscriptionData(this.getSubscriptionId());
        }
        
        if (subscription == undefined) return;

        this.setState({
            quantity: subscription.qty_subscribed,
            billingPeriod: this.getInitalBillingPeriod(),
            billingAddressId: subscription.billing_address_id,
            shippingAddressId: subscription.shipping_address_id
        });
    }

    getSubscriptionId() {
        const url = new URL(window.location.href);
        return url.searchParams.get('id');
    }

    getPrice() {
        const { subscription: {billing_amount, tax_amount, currency_code, qty_subscribed} } = this.props;
        const { quantity } = this.state;
        const price = (billing_amount + tax_amount) * quantity;
        return `${price.toFixed(2)} ${currency_code}`;
    }

    componentDidUpdate() {
        const { subscription } = this.props;
        if (subscription == undefined) return;
        if (!this.isStateSet()) {
            var initialBillingPeriod = this.getInitalBillingPeriod();
            this.setState({
                quantity: subscription.qty_subscribed,
                billingPeriod: initialBillingPeriod,
                billingAddressId: subscription.billing_address_id,
                shippingAddressId: subscription.shipping_address_id
            });
        };
    }

    isStateSet() {
        const { quantity, billingPeriod, billingAddressId, shippingAddressId } = this.state;
        if (quantity && billingPeriod != '' && billingAddressId >= 0 && shippingAddressId >= 0) {
            return true;
        }
        return false
    }

    getInitalBillingPeriod() {
        const { subscription, subscriptionIntervals } = this.props;

        var billingPeriod = '';
        subscriptionIntervals.forEach(({ key }, index) => {
            if (key == subscription.billing_frequency_cycle) {
                billingPeriod = key;
            }
        });

        return billingPeriod;
    }

    onQuantityChanged(value) {
        this.setState({ quantity: value });
    }

    onBillingPeriodChanged(value) {
        const { subscriptionIntervals } = this.props;
        subscriptionIntervals.forEach(({ key, interval_label }, index) => {
            if (key == value) {
                this.setState({ billingPeriod: value, billingLabel: interval_label });
            }
        });
    }

    onBillingAddressChanged(value) {
        this.setState({ billingAddressId: value });
    }

    onShippingAddressChanged(value) {
        this.setState({ shippingAddressId: value });
    }

    onUpdateClicked() {
        const { quantity, billingAddressId, shippingAddressId, billingPeriod, billingLabel } = this.state;
        const { updateSubscription, changePage, subscription, updateIsLoading } = this.props;

        var subscriptionId = subscription.subscription_id;
        var data = {
            qty: quantity,
            subscription_start_date: '',
            md_billing_address: billingAddressId,
            md_shipping_address: shippingAddressId,
            md_billing_frequency: billingPeriod,
            billing_label: billingLabel
        }

        updateIsLoading(true);
        updateSubscription(subscription, data);
        // history.back();
        // changePage('view')
        history.back();
    }

    prepareBillingPeriodSelectOptions() {
        const { subscriptionIntervals } = this.props;
        var selectOptions = [];
        if (subscriptionIntervals == undefined) {
            var option = {
                id: '',
                value: '',
                disable: false,
                label: ''
            }
            selectOptions.push(option);
        } else {
            subscriptionIntervals.forEach(({ key, interval_label }, index) => {
                var option = {
                    id: key,
                    value: key,
                    disable: false,
                    label: interval_label
                }
                selectOptions.push(option);
            });
        }

        return selectOptions;
    }

    prepareAddressSelectOptions() {
        const { addresses } = this.props;
        var selectOptions = [];

        if (addresses == undefined) return;

        addresses.forEach(address => {
            var name = address.middlename != null ? `${address.firstname} ${address.middlename} ${address.lastname}` : `${address.firstname} ${address.lastname}`;
            var option = {
                id: address.id,
                value: address.id,
                disable: false,
                label: `#${address.id} - ${name}, ${address.postcode} ${address.street[0]}, ${address.city}, ${address.telephone}, ${this.getCountryFromCode(address.country_id)}`
            }
            selectOptions.push(option);
        });

        return selectOptions;
    }

    getCountryFromCode(countryCode) {
        var regionNames = new Intl.DisplayNames([this.getLanguageContext(countryCode)], { type: 'region' });
        return regionNames.of(countryCode);
    }

    getLanguageContext(countryCode) {
        switch (countryCode) {
            case "DK":
                return 'da';

            case "US":
                return 'en';

            case "UK":
                return 'en';

            default:
                return 'en';
        }
    }

    render() {
        return (
            <SubscriptionEdit
                {...this.props}
                {...this.state}
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionEditContainer);
