/* eslint-disable */
import { EPAY_CODE, EPAY_SUBSCRIPTION } from '../component/Epay/Epay.config';
import EpayQuery from '../query/Epay.query';
import { isSignedIn } from 'Util/Auth';
import { deleteGuestQuoteId } from 'Util/Cart';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { EPAY_PAYMENT_TOTALS, EPAY_PAYMENT_ITEMS } from '../component/Epay/Epay.config';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getGuestQuoteId } from 'Util/Cart';
import {
    PAYMENT_TOTALS
} from 'Route/Checkout/Checkout.config';
import { fetchQuery, fetchMutation } from 'Util/Request';
import CheckoutQuery from 'Query/Checkout.query';

const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    const [paymentInformation] = args;
    const {
        paymentMethod: {
            code,
            additional_data
        } = {}
    } = paymentInformation;

    if (code === EPAY_CODE || code === EPAY_SUBSCRIPTION) {
        const guest_cart_id = !isSignedIn() ? getGuestQuoteId() : '';
        try {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                guest_cart_id,
                payment_method: {
                    code,
                    [code]: additional_data
                }
            }));

            const { orderComment } = instance.state;
            const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id, orderComment));
            const {
                placeOrder: {
                    order: {
                        order_id
                    }
                }
            } = orderData;

            const {
                totals: { items = [] }
            } = instance.props;
            const { paymentTotals: totals } = instance.state;

            BrowserDatabase.setItem(totals, EPAY_PAYMENT_TOTALS, ONE_MONTH_IN_SECONDS);
            BrowserDatabase.setItem(items, EPAY_PAYMENT_ITEMS, ONE_MONTH_IN_SECONDS);

            const { resetCart, resetGuestCart } = instance.props;

            if (!isSignedIn()) {
                deleteGuestQuoteId()
            }

            BrowserDatabase.deleteItem(PAYMENT_TOTALS);

            if (isSignedIn()) {
                resetCart();
            } else {
                resetGuestCart();
            }

            const epayCheckout = await fetchQuery(EpayQuery.getEpayCheckout(order_id));
            const { epayCheckout: { epay_payment_window } } = epayCheckout;
            const epayInfo = JSON.parse(epay_payment_window);
            
            if (!window.PaymentWindow){
                addScript();
            }
            const { PaymentWindow } = window;

            const paymentWindow = new PaymentWindow(epayInfo);
            paymentWindow.open();
        } catch (e) {
            instance._handleError(e);
        }
    } else {
        return callback.apply(instance, args);
    }
}


const addScript = () => {
    const { PaymentWindow } = window;
    if (!PaymentWindow){
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://ssl.ditonlinebetalingssystem.dk/integration/ewindow/paymentwindow.js`;
        if (typeof(script.async) != "undefined") { script.async = true; }
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(script, s);
    }
}

const onBillingPage = (args, callback, instance) => {
    setTimeout(addScript, 500);
    return callback(...args);
}

export default {
    'Route/Checkout/Component': {
        'member-function': {
            renderBillingStep: onBillingPage
        }
    },
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder
        }
    }
}
