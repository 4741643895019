/* eslint-disable */

// imports the original component from vendor folder
import HoeksBlogCategories from 'scandipwa-blog/src/component/BlogCategories/BlogCategories.component';

import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import { MAX_CATEGORIES } from 'scandipwa-blog/src/component/BlogCategories/BlogCategories.config';

// import new extended styles file
import './BlogCategories.override.style.scss';

export class BlogCategories extends HoeksBlogCategories {
    state = {
        activeCategory: ''
    }

    renderContent() {
        const { categories, isLoaded } = this.props;
        const category = {};

        if (!isLoaded) {
            return Array(MAX_CATEGORIES).fill().map((_, i) => {this.renderCategory(category, i)});
        }

        if (isLoaded && categories.length === 0) {
            return __('No categories');
        }

        return categories.map(this.renderCategory.bind(this));
    }

    renderCategory(category, key, setCurrentCategory) {
        const { title, identifier } = category;
        const { activeCategory } = this.state;

        const isActive = title == activeCategory;
        return (
            <div block="BlogCategories" elem="Category" mods={{ isActive: isActive }} onClick={() => this.setState({activeCategory: title})} key={ key }>
                <Link to={ `blog?category=${identifier}` } title={ __('View %s', title) }>
                    <h3 block="BlogCategories" elem="CategoryTitle">
                        <TextPlaceholder
                          mix={ { block: 'BlogCategories', elem: 'CategoryTitlePlaceholder' } }
                          content={ title }
                          length="long"
                        />
                    </h3>
                </Link>
            </div>
        );
    }
    
    renderAllCategoriesButton() {
        const { activeCategory } = this.state;

        const isActive = activeCategory === '';
        return (
            <div block="BlogCategories" elem="Category" mods={{ isActive: isActive }} onClick={() => this.setState({activeCategory: ''})}>
                <Link to="/blog">
                    <h3 block="BlogCategories" elem="CategoryTitle">{__('All')}</h3>
                </Link>
            </div>
        );
    }

    render() {
        return (
            <div block="BlogCategories" elem="Wrapper">
                { this.renderAllCategoriesButton() }
                { this.renderContent() }
            </div>
        );
    }

}

export default BlogCategories;
