/* eslint-disable */

const _getArgumentsMap = (args, callback, instance) => {
    const result = callback.apply(instance, args);

    return {
        ...result,
        token: { type: 'String' }
    };
}

export default {
    'Query/ProductList': {
        'member-function': {
            _getArgumentsMap,
        }
    }
}