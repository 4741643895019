/* eslint-disable */
import { ExpandableContent as SourceExpandableContent } from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.override.style';

export * from 'SourceComponent/ExpandableContent/ExpandableContent.component';

/** @namespace HoeksPwa/Component/ExpandableContent/Component */
export class ExpandableContent extends SourceExpandableContent {
    componentDidMount() {
        const { children, mix } = this.props;
        const { isContentExpanded } = this.state;
        const mods = { isContentExpanded };

        if( mix?.block == 'ProductInformation' )
            this.setState({ isContentExpanded: true }
        );
    }
}

export default ExpandableContent;
