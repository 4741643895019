/* eslint-disable */
import { getAuthorizationToken } from 'Util/Auth';
import { hash } from 'Util/Request/Hash';

const requestProductList = (args, callback, instance) => {
    const { privateLabelCategoryId } = instance.props;
    const [ options ] = args;
    const {
        args: optionArgs = {},
        args: {
            filter: {
                categoryIds
            } = {}
        } = {}
    } = options;
    const token = getAuthorizationToken();
    const tokenOption = (token && (categoryIds == privateLabelCategoryId || Array.isArray(categoryIds) && categoryIds.some(id => id == privateLabelCategoryId)))
        ? { token: hash(token) } : {}
    const updatedOptions = {
        ...options,
        args: {
            ...optionArgs,
            ...tokenOption
        }
    }

    return callback.apply(instance, [ updatedOptions ]);
}

const mapStateToProps = (args, callback, instance) => {
    const [ state ] = args;

    return {
        ...callback.apply(instance, args),
        privateLabelCategoryId: state.ConfigReducer.private_label_category_category_id
    };
}

export default {
    'Component/CategoryProductList/Container': {
        'member-function': {
            requestProductList
        }
    },
    'Component/CategoryProductList/Container/mapStateToProps': {
        'function': mapStateToProps
    }
}