/* eslint-disable */
import PrivateLabelLink from '../component/PrivateLabelLink';

const renderLogoutTab = (args, callback, instance) => {
    return (
        <>
            <PrivateLabelLink />
            { callback.apply(instance, args) }
        </>
    );
}

export default {
    'Component/MyAccountTabList/Component': {
        'member-function': {
            renderLogoutTab
        }
    }
}