/* eslint-disable */
import { ProductCard as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';

import TextPlaceholder from 'Component/TextPlaceholder';
import ProductPrice from 'Component/ProductPrice';
import { BUNDLE, CONFIGURABLE, GROUPED } from 'Util/Product';

import './ProductCard.override.style';

export class ProductCard extends SourceProductCard {
    renderProductCardWishlistButton() {
        return (
            null
        );
    }

    renderProductPrice() {
        const {
            product: { price_range, type_id }
        } = this.props;

        if (!price_range) {
            return <TextPlaceholder />;
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                { this.renderTierPrice() }
                { this.renderProductTypePriceBadge() }
                <p>
                    {type_id == CONFIGURABLE ? <span block="ProductCard" elem="FromPrice">{__('From:')}</span> : <></>}
                    <ProductPrice
                      price={ price_range }
                      mix={ { block: 'ProductCard', elem: 'Price', mods: { isConfig: type_id == CONFIGURABLE } } }
                    />
                </p>
            </div>
        );
    }


    renderBadges() {
        const {
            product: {
                attributes: {
                    product_badges: {
                        attribute_options = {}
                    } = {}
                } = {},
                stock_status
            } = {},
            productHasSpecialPrice,
            productIsInStock
        } = this.props;

        // multiselect badges
        let badgesAttr = false;
        const badgesToRender = [];

        if (Object.keys(attribute_options).length != 0) {
            badgesAttr = attribute_options;
        } else {
            badgesAttr = {};
        }

        for (var key of Object.keys(badgesAttr)) {
            if (badgesAttr[key]?.label == 'newproduct') {
                badgesToRender.push({
                    label: __('New Product'),
                    class: 'BadgeNewProduct'
                })
            }
            if (badgesAttr[key]?.label == 'monthscoffee') {
                badgesToRender.push({
                    label: __('Coffee of the Month'),
                    class: 'BadgeMonthsCoffee'
                })
            }
            if (badgesAttr[key]?.label == 'roastprofile') {
                badgesToRender.push({
                    label: __('New Roast Profile'),
                    class: 'BadgeRoastProfile'
                })
            }
            if (badgesAttr[key]?.label == 'restsalg') {
                badgesToRender.push({
                    label: __('Clearance'),
                    class: 'RestSalg'
                })
            }
        }

        // special price badge 
        if (productHasSpecialPrice()) {
            badgesToRender.push({
                label: __('On Sale'),
                class: 'SpecialPrice'
            })
        }

        // console.log(productIsInStock);
        if (stock_status && !productIsInStock) {
            badgesToRender.push({
                label: __('Out of Stock'),
                class: 'NoStock'
            })
        }

        return (
            <div block="ProductCard" elem="Badges">
                {badgesToRender.map((badge) => <div block="ProductCard" elem={"Badge " + badge.class}>{badge.label}</div>)}
            </div>
        )
    }

    renderProcessingType() {
        const { product } = this.props;
        const { product: { attributes: { beans: { attribute_options : processing_options = {} } = {} } = {} } = {} } = this.props;

        const processing = Object.keys(processing_options).map((key) => (
            processing_options[key].label
        ));

        const processingString = processing.join(', ');

        if (!processingString || processingString == '') {
            return null;
        }
        
        return (
            <div block="ProductCard" elem="ProcessingType">
                {processingString}
            </div>
        );
    }

    renderBeanType() {
        const { product } = this.props;
        const { product: { attributes: { sort: { attribute_options : sorts_options = {} } = {} } = {} } = {} } = this.props;
        const { product: { attributes: { coffee_strength: { attribute_options : strength_options = {} } = {} } = {} } = {} } = this.props;

        const sorts = Object.keys(sorts_options).map((key) => (
            sorts_options[key].label
        ));

        const sortsString = sorts.join(', ');

        const strength = Object.keys(strength_options).map((key) => (
            strength_options[key].label
        ));

        const strengthString = strength.join(', ');

        if ((!sortsString || sortsString == '') && (!strengthString || strengthString == '')) {
            return null;
        }

        let typeString = '';
        if (!sortsString == '' && !strengthString == '')
        {
            typeString = sortsString + ', ' + strengthString;
        } else if (!sortsString == '') {
            typeString = sortsString;
        } else if (!strengthString == '') {
            typeString = strengthString;
        }

        return (
            <div block="ProductCard" elem="BeanType">
                {typeString}
            </div>
        );
    }

    renderBrewingType() {
        const { product } = this.props;
        const { product: { attributes: { brewing_type: { attribute_options : brewing_options = {} } = {} } = {} } = {} } = this.props;

        const brewing = Object.keys(brewing_options).map((key) => (
            brewing_options[key].label
        ));

        const brewingString = brewing.join(', ');

        if (!brewingString || brewingString == '') {
            return null;
        }

        return (
            <div block="ProductCard" elem="BrewingType">
                {brewingString}
            </div>
        );
    }

    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <div block="ProductCard" elem="AfricaImage" />
                        <figure block="ProductCard" elem="Figure">
                            {this.renderPicture()}
                        </figure>
                        {this.renderBadges()}
                        {this.renderReviews()}
                    </div>
                    <div block="ProductCard" elem="Content">
                        {this.renderMainDetails()}
                        {this.renderProcessingType()}
                        {this.renderBeanType()}
                        {this.renderBrewingType()}
                        {this.renderProductPrice()}
                        {this.renderVisualConfigurableOptions()}
                    </div>
                </>
            ))
        );
    }

    renderCardListContent() {
        const { children, layout, renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <div block="ProductCard" elem="Link">
                {this.renderCardLinkWrapper(
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            {this.renderPicture()}
                        </figure>
                    </div>
                )}
                <div block="ProductCard" elem="Content" mods={{ layout }}>
                    <div block="ProductCard" elem="MainInfo">
                        {/* {this.renderAdditionalProductDetails()} */}
                        {this.renderMainDetails()}
                        {this.renderReviews()}
                    </div>
                    <div block="ProductCard" elem="AttributeWrapper">
                        {this.renderProductPrice()}
                        {this.renderVisualConfigurableOptions()}
                    </div>
                    <div block="ProductCard" elem="ActionWrapper">
                        {this.renderAddToCart()}
                        {this.renderProductActions()}
                    </div>
                    <div block="ProductCard" elem="AdditionalContent">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductCard;
