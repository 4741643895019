/* eslint-disable */
import { CheckoutSuccess as SourceCheckoutSuccess } from 'SourceComponent/CheckoutSuccess/CheckoutSuccess.component';
import { REGISTER } from 'Component/Header/Header.config';
import { isSignedIn } from 'Util/Auth';
import Link from 'Component/Link';
import { appendWithStoreCode } from 'Util/Url';

export class CheckoutSuccess extends SourceCheckoutSuccess {
    renderCreateAccountButton() {
        const {
            isEmailAvailable,
            email,
            firstName,
            lastName
        } = this.props;

        if (!isEmailAvailable || isSignedIn()) {
            return null;
        }

        return (
            <div block="CheckoutRegistrationLink">
                <p>
                    { __('You can track your order status by creating an account.') }
                </p>
                <p>
                    { `${__('Email address')}: ${email}` }
                </p>
                <Link
                  to={ {
                      pathname: appendWithStoreCode(`${ REGISTER }`),
                      state: {
                          firstName,
                          lastName,
                          email
                      }
                  } }
                  block="Button"
                >
                    { __('Create account') }
                </Link>
            </div>
        );
    }
}

export default CheckoutSuccess