/* eslint-disable */
import SourceImage from 'HoeksComponent/Image/Image.component';

import {
    IMAGE_LOADED, IMAGE_LOADING, IMAGE_NOT_FOUND, IMAGE_NOT_SPECIFIED
} from 'Component/Image/Image.config';
/**
 * Image component
 * Images are loaded only when they appear in a viewport
 * @class Image
 * @namespace Component/Image/Component
 */
export class Image extends SourceImage {
    renderImage() {
        const {
            alt,
            isPlaceholder,
            src,
            srcSet,
            sizes,
            style,
            title
        } = this.props;
        const { imageStatus } = this.state;

        if (isPlaceholder) {
            return null;
        }
        switch (imageStatus) {
        case IMAGE_NOT_FOUND:
            return this.renderImageNotFound();
        case IMAGE_NOT_SPECIFIED:
            return (
                <span block="Image" elem="Content">{ __('Image not specified') }</span>
            );
        case IMAGE_LOADED:
        case IMAGE_LOADING:
            return (
                <img
                  block="Image"
                  elem="Image"
                  src={ src || '' }
                  alt={ alt }
                  mods={ { isLoading: imageStatus === IMAGE_LOADING } }
                  style={ style }
                  title={ title }
                  onLoad={ this.onLoad }
                  onError={ this.onError }
                  loading="lazy"
                />
            );
        default:
            return null;
        }
    }
}

export default Image;
