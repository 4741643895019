/* eslint-disable */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { getSortedItems } from 'Util/Menu';
import MenuItem from 'Component/MenuItem';

import Overlay from 'Component/Overlay';
import Menu from 'SourceComponent/Menu/Menu.component';
import './MenuOverlay.base.override.style.scss';

/** @namespace Component/MenuOverlay/Component */
export class MenuOverlayComponent extends Menu {
    static propTypes = {
        isHideOverlay: PropTypes.bool,
        isActive: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isHideOverlay: false
    };

    renderTopLevel() {
        return (
            <>
                { this.renderSearchField() }
                { this.renderNav() }
                { super.renderTopLevel() }
            </>
        )
    }

    renderSearchField() {
        return null;
    }

    renderNav(){
        const { activeMenuItemsStack, menu, goBack } = this.props;
        const [mainMenu] = Object.values(menu);
        const isVisible = !!activeMenuItemsStack.length;
        const currentItem = activeMenuItemsStack.slice().reduceRight((acc, itemId) => {
            const {
                children: {
                    [itemId]: currentItem
                }
            } = acc;

            return currentItem;
        }, mainMenu) ?? {};

        const { title } = currentItem;

        return (
            <nav block="Header" elem="Nav">
                <button 
                    block="Header" 
                    elem="Button" 
                    mods={ { type: 'back', isVisible } }
                    onClick={goBack}
                    aria-label="Go back"
                    aria-hidden={ !isVisible }
                    tabIndex={ isVisible ? 0 : -1 }></button>
                <p block="Header" elem="Title" mods={ { isVisible } }>{ title }</p>
            </nav>
        );
    }

    renderSubLevel(category) {
        const { activeMenuItemsStack } = this.props;
        const { item_id, children } = category;
        const childrenArray = getSortedItems(Object.values(children));
        const isVisible = activeMenuItemsStack.includes(item_id);
        const subcategoryMods = { type: 'subcategory' };

        return (
            <div
              block="Menu"
              elem="SubMenu"
              mods={ { isVisible } }
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="ItemList"
                  mods={ { ...subcategoryMods } }
                >
                    { this.renderMobileLink(category) }
                    { childrenArray.map(this.renderSubLevelItems) }
                </div>
            </div>
        );
    }

    renderFirstLevelItems(item) {
        const {
            activeMenuItemsStack,
            handleSubcategoryClick,
            onCategoryHover,
            closeMenu,
            device
        } = this.props;

        const { children } = item;
        const childrenArray = Object.values(children);
        const itemMods = { type: 'main' };

        if (childrenArray.length) {
            return (
                <div
                  // TODO: split into smaller components
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleSubcategoryClick(e, item) }
                  tabIndex="0"
                  block="Menu"
                  elem="SubCatLink"
                  role="button"
                >
                    <MenuItem
                      activeMenuItemsStack={ activeMenuItemsStack }
                      item={ item }
                      itemMods={ itemMods }
                      onCategoryHover={ onCategoryHover }
                      closeMenu={ closeMenu }
                    />
                    { this.renderSubLevel(item) }
                </div>
            );
        }

        return (
            <MenuItem
              activeMenuItemsStack={ activeMenuItemsStack }
              item={ item }
              itemMods={ itemMods }
              onCategoryHover={ onCategoryHover }
              closeMenu={ closeMenu }
              isLink
            />
        );
    }

    renderMobileLink(category){
        const {
            activeMenuItemsStack,
            closeMenu,
            device
        } = this.props;
        if (!device.isMobile) {
            return null;
        }
        const {
            item_id,
            item_class,
            title
        } = category;
        const isBanner = item_class === 'Menu-ItemFigure_type_banner';
        const isHideOnDesktop = item_class === 'Menu-ItemFigure_type_hideOnDesktop';
        const mods = {
            isBanner: !!isBanner,
            isHideOnDesktop: !!isHideOnDesktop
        };
        return (
            <div
              block="Menu"
              elem="SubItemWrapper"
              key={ item_id }
              mods={ mods }
            >
                <MenuItem
                  activeMenuItemsStack={ activeMenuItemsStack }
                  item={ {...category,  title: __('See all in %s', title)} }
                  closeMenu={ closeMenu }
                  isLink
                />
            </div>
        );
    }


    render() {
        const { isActive, activeMenuItemsStack } = this.props;
        if (!isActive) return null;
        const isSubMenuActive = Array.isArray(activeMenuItemsStack) && activeMenuItemsStack.length > 0;
        return (
            <Overlay
              id="menu"
              mix={ { block: 'MenuOverlay', mods: { isActive, isSubMenuActive } } }
            >
                <article
                  block="MenuOverlay"
                  elem="Results"
                  aria-label="Search results"
                >
                    { super.render() }
                </article>
            </Overlay>
        );
    }
}

export default MenuOverlayComponent;
