/* eslint-disable */

const _getStoreConfigFields = (args, callback, instance) => {
    return [
        ...callback.apply(instance, args),
        'private_label_category_category_id'
    ]
}

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields
        }
    }
}