/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Menu as HoeksMenu } from 'HoeksComponent/Menu/Menu.component';

import { getSortedItems } from 'Util/Menu';
import MenuItem from 'Component/MenuItem';

import './Menu.override.style';

export * from 'HoeksComponent/Menu/Menu.component';

/** @namespace Component/Menu/Component */
export class Menu extends HoeksMenu {
    renderPromotionCms() {
        return null;
    }

    renderDesktopSubLevelItemsIcon(item) {
        const {
            icon,
            title
        } = item;

        const iconSrc = (icon) ? `/pub/media/${icon}` : null;

        if (iconSrc) {
            return (
                <img src={iconSrc} alt={title} />
            );
        }

        return null;
    }

    renderDesktopSubLevelItems(item, mods) {
        const {
            item_id,
            item_class
        } = item;
        const { closeMenu, activeMenuItemsStack } = this.props;

        const isHideOnDesktop = item_class === 'Menu-ItemFigure_type_hideOnDesktop';
        const isGridImage = item_class.indexOf('GridImage') > -1;
        const itemMods = { ...mods, isHideOnDesktop, isGridImage };

        return (
            <div
              block="Menu"
              elem="SubLevelDesktopItemWrapper"
              mods={{ item_class }}
              key={ item_id }
            >
                {/* { this.renderDesktopSubLevelItemsIcon(item) } */}
                <MenuItem
                    activeMenuItemsStack={ activeMenuItemsStack }
                    item={ item }
                    itemMods={ itemMods }
                    closeMenu={ closeMenu }
                    isLink
                />
            </div>
        );
    }

    renderDesktopSubLevel(category) {
        const { device } = this.props;
        const { children, item_class, item_id } = category;
        const childrenArray = getSortedItems(Object.values(children));

        if (device.isMobile || !childrenArray.length) {
            return null;
        }

        const isBanner = item_class === 'Menu-ItemFigure_type_banner';
        const isLogo = item_class === 'Menu-ItemFigure_type_logo';
        const mods = {
            isBanner: !!isBanner,
            isLogo: !!isLogo,
            type_subLevelDesktop: true
        };

        return (
            <div
              block="Menu"
              elem="SubLevelDesktop"
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="ItemList"
                  mods={ { ...mods } }
                >
                    { childrenArray.map((item) => this.renderDesktopSubLevelItems(item, mods)) }
                </div>
            </div>
        );
    }

    renderSubLevelItems = (item) => {
        const {
            handleSubcategoryClick,
            activeMenuItemsStack,
            onCategoryHover,
            closeMenu,
            device
        } = this.props;

        const {
            item_id,
            children,
            item_class
        } = item;

        const isBanner = item_class === 'Menu-ItemFigure_type_banner';
        const isHideOnDesktop = item_class === 'Menu-ItemFigure_type_hideOnDesktop';
        const mods = {
            isBanner: !!isBanner,
            isHideOnDesktop: !!isHideOnDesktop,
            item_class
        };

        const childrenArray = Object.values(children);
        const subcategoryMods = { type: 'subcategory' };

        if (childrenArray.length && device.isMobile) {
            return (
                <div
                  key={ item_id }
                  // TODO: split into smaller components
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleSubcategoryClick(e, item) }
                  tabIndex="0"
                  role="button"
                >
                    <MenuItem
                      activeMenuItemsStack={ activeMenuItemsStack }
                      item={ item }
                      itemMods={ subcategoryMods }
                      onCategoryHover={ onCategoryHover }
                      closeMenu={ closeMenu }
                    />
                    { this.renderSubLevel(item) }
                </div>
            );
        }

        return (
            <div
              block="Menu"
              elem="SubItemWrapper"
              key={ item_id }
              mods={ mods }
            >
                <MenuItem
                  activeMenuItemsStack={ activeMenuItemsStack }
                  item={ item }
                  closeMenu={ closeMenu }
                  isLink
                />
                { this.renderDesktopSubLevel(item) }
            </div>
        );
    };

    renderFirstLevel = (item) => {
        const { item_id, item_class } = item;

        const isHideOnDesktop = item_class === 'Menu-ItemFigure_type_hideOnDesktop';

        return (
            <li
              block="Menu"
              elem="Item"
              key={ item_id }
              mods={ { isHideOnDesktop, item_class } }
            >
                { this.renderFirstLevelItems(item) }
            </li>
        );
    };

    renderTopLevel() {
        const { menu } = this.props;
        const categoryArray = Object.values(menu);

        if (!categoryArray.length) {
            return null;
        }

        const [{ children, title: mainCategoriesTitle }] = categoryArray;
        const childrenArray = getSortedItems(Object.values(children));

        return (
            <>
                <div block="Menu" elem="MainCategories">
                    <ul
                      block="Menu"
                      elem="ItemList"
                      mods={ { type: 'main' } }
                      aria-label={ mainCategoriesTitle }
                    >
                        { childrenArray.map(this.renderFirstLevel) }
                    </ul>
                    { this.renderAdditionalInformation(true) }
                </div>
                { this.renderSubMenuDesktop(children) }
            </>
        );
    }
}

export default Menu;
