/* eslint-disable */

// imports the original component from vendor folder
import SourceClerk from '@hoeks/clerk/src/component/Clerk/Clerk.component';
// import { ClerkComponent as SourceClerkComponent } from '@hoeks/clerk/src/component/Clerk/Clerk.component';

import ContentWrapper from 'Component/ContentWrapper';
import Slider from '@hoeks/slider-widget/src/component/DraggableSlider';

// import new extended styles file
import './Clerk.override.style.scss';

export class Clerk extends SourceClerk { 
    renderHeading() {
        const { title } = this.props;
        return (
            <h3 block="Clerk" elem="Title">
                { title }
            </h3>
        );
    }

    _sliderSettings(){
        const { slidesToShow } = this.props;
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: slidesToShow,
            slidesToScroll: slidesToShow,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }             
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }             
                }
            ]
        };

        return settings;
    }

    render() {
        const settings = this._sliderSettings();

        return (
            <ContentWrapper
              mix={ { block: 'Clerk' } }
              wrapperMix={ { block: 'Clerk', elem: 'Wrapper' } }
              label={ __('Linked products') }
            >
                { this.renderHeading() }
                <ul block="Clerk" elem="Slider">
                <Slider {...settings}>
                    { this.renderItems(true) }
                </Slider>
                </ul>
            </ContentWrapper>
        );
    }
}

export default Clerk; 