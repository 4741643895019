import { mapDispatchToProps } from 'scandipwa-blog/src/route/PostsDetails/PostsDetails.container';
import { connect } from 'react-redux';

import getStore from 'Util/Store';
import PostsDetails from './PostsDetails.component';

//import './PostsDetails.override.style';

export * from 'scandipwa-blog/src/route/PostsDetails/PostsDetails.container';

/** @namespace HoeksPWA/Blog/Route/PostsDetails/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    post: state.PostsDetailsReducer.post
});

export default connect(mapStateToProps, mapDispatchToProps)(PostsDetails);
