import { Field } from 'Util/Query';

/** @namespace Hoeks/Clerk/Query/Clerk/Query/ClerkQuery */
export class ClerkQuery {
    getApiKey() {
        return new Field('StoreConfig')
        .addFieldList(['clerk_api_key']);
    }
}

export default new ClerkQuery();
