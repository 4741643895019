/* eslint-disable */
import { NavigationAbstractContainer as SourceNavigationAbstractContainer, mapStateToProps as sourceMapStateToProps, mapDispatchToProps } from 'SourceComponent/NavigationAbstract/NavigationAbstract.container';
import { connect } from 'react-redux';
export * from 'SourceComponent/NavigationAbstract/NavigationAbstract.container';
import { DEFAULT_STATE_NAME } from './NavigationAbstract.config';
export const DEFAULT_STATE = { name: DEFAULT_STATE_NAME };
import history from 'Util/History';

export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps
});

export class NavigationAbstractContainer extends SourceNavigationAbstractContainer {
    handleDesktopRouteChange() {
        const {
            hideActiveOverlay,
            setNavigationState
        } = this.props;
        const { location: { state: { category = 0 } = {} } = {} } = history;
        
        setNavigationState(this.routeMap['/']);
        if (!category) {
            hideActiveOverlay();
        }
        return {};
    }

    handleMobileRouteChange(history) {
        const {
            // hideActiveOverlay,
            setNavigationState,
            navigationState: { name }
        } = this.props;
        // console.log('debug mobile');
        const { pathname } = history;

        // Find the new state name
        const newNavigationState = this.getNavigationState();

        // Update the state if new name is set
        if (name !== newNavigationState.name) {
            setNavigationState(newNavigationState);
        }

        // hideActiveOverlay();

        return { prevPathname: pathname };
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(NavigationAbstractContainer);
