/* eslint-disable */
import { SomethingWentWrong as SourceSomethingWentWrong } from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.component';

export class SomethingWentWrong extends SourceSomethingWentWrong {
    renderErrorDetails() {
        const { errorDetails: { err, info: { componentStack } = {}, err: { stack } = {} } } = this.props;
        const errorString = err.toString();

        /* send error info to server */
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/hoeks/exception/logging', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.send(JSON.stringify({
            errorMessage: errorString,
            componentStack: componentStack,
            stack: stack,
            url: window.location.href
        }));

        console.groupCollapsed('Suppressed error log:');
        console.error(errorString);
        console.groupEnd();

        if (process.env.NODE_ENV === 'production') {
            return null; 
        }

        return (
            <div block="SomethingWentWrong" elem="Debug">
                { errorString }
                { componentStack }
            </div>
        );
    }
}

export default SomethingWentWrong;