// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Field from 'Component/Field';
import Form from 'Component/Form';
import FormPortal from 'Component/FormPortal';
import { TEXT_TYPE } from 'Component/Field/Field.config';

import './ProductAlert.style';

/** @namespace Hoeks/ProductAlert/Component/ProductAlert/Component/ProductAlertComponent */
export class ProductAlertComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderHoneyPotTrap() {
        return (
            <>
                <label className='ohnohoney'>Email</label>
                <Field className='ohnohoney' autoComplete="off" type="text" id="email" name="email" placeholder='your email here'/>
            </>
        )
    }

    renderFields() {
        return (
            <div block="ProductAlert" elem="Fields">
                <Field
                    id="emailwarding"
                    name="emailwarding"
                    type={TEXT_TYPE}
                    validation={['notEmpty', 'email']}
                    placeholder={__('Email')}
                />
                {this.renderHoneyPotTrap()}
            </div>
        );
    }

    renderActions() {
        const { onFormSuccess, onFormError } = this.props;

        return (
            <div block="ProductAlert">
                <Form
                    id={'PRODUCT_ALERT'}
                    onSubmitSuccess={(fields) => onFormSuccess(fields)}
                    onSubmitError={(fields) => onFormError(fields)}
                >
                    <button 
                        type="submit" 
                        block="Button"
                    >
                        {__('Notify me')}
                    </button>
                </Form>
            </div>
        );
    }

    render() {
        return (
            <div block="ProductAlert">
                <FormPortal
                    id={'PRODUCT_ALERT'}
                    name="product_alert"
                >
                    {this.renderFields()}
                </FormPortal>
                {this.renderActions()}
            </div>
        );
    }
}

export default ProductAlertComponent;
