/* eslint-disable */
// import PropTypes from 'prop-types';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ProductPrice from 'Component/ProductPrice';
import Field from 'Component/Field'
import CmsBlock from 'Component/CmsBlock';
import { SELECT_TYPE, CHECKBOX_TYPE } from 'Component/Field/Field.config'
import { PriceType, ProductType } from 'Type/ProductList';
import Link from 'Component/Link';
import { appendWithStoreCode } from 'Util/Url';

import './SubscriptionActions.style';

/** @namespace Hoeks/Subscription/Component/SubscriptionActions/Component/SubscriptionActionsComponent */
export class SubscriptionActionsComponent extends PureComponent {
    static propTypes = {
        subscription_type: PropTypes.string.isRequired,
        productPrice: PriceType
    };

    renderOneTimePayment() {
        if (this.props.subscription_type != "either") return null;
        const { acceptedOneTimePayment, onOneTimePaymentAccept, product_type, offerCount } = this.props;

        return (
            <div block="SubscriptionActions" elem="OneTimePayment">
                <Field
                    id="OneTimePayment_Toggle"
                    name="OneTimePayment_Toggle"
                    checked={acceptedOneTimePayment}
                    onChange={onOneTimePaymentAccept}
                    type="checkbox"
                />
                <p>{__('No subscription')}</p>
                {product_type == CONFIGURABLE ? <p block="SubscriptionActions" elem="FromPrice">{__('From:')}</p> : <></>}
                <ProductPrice
                    isSchemaRequired
                    price={this.props.productPrice}
                    variantsCount={offerCount}
                    block="OneTimePayment"
                    elem="Price"
                />
            </div>
        );
    }

    renderSubscription() {
        const { onSubscriptionAccept, acceptedSubscription, onFieldSelectChanged, prepareSelectOptionsData, subscription_price_range, productPrice } = this.props;
        const { minimum_price: { discount: {amount_off = 0, percent_off = 0} = {}, regular_price: { value = 0 } = {} } = {} } = subscription_price_range;
        const showDiscount = amount_off || percent_off ? true : false;
        return (
            <div block="SubscriptionActions" elem="Subscription">
                <Field
                    id="Subscription_Toggle"
                    name="Subscription_Toggle"
                    checked={acceptedSubscription}
                    onChange={onSubscriptionAccept}
                    type="checkbox"
                />
                <p>{__('Subscribe and get free shipping')}</p>
                <div block="SubscriptionActions" elem="SubscriptionOption">
                    <p>{__('Per cycle')}</p>
                    <ProductPrice
                        isSchemaRequired={false}
                        price={showDiscount ? subscription_price_range : productPrice}
                        block="SubscriptionActions"
                        elem="Price"
                    />
                    <div block="SubscriptionActions" elem="SubscriptionIntervals">
                        <Field
                            type={SELECT_TYPE}
                            selectOptions={prepareSelectOptionsData()}
                            onChange={(selctedValue) => onFieldSelectChanged(selctedValue)}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderSubscriptionPrices() {
        return (
            <div
                block="SubscriptionActions"
                elem="PriceWrapper"
            >
                {this.renderOneTimePayment()}
                {this.renderSubscription()}
            </div>
        );
    }

    renderSubscriptionDescription() {
        const { subscriptionDescriptionUrl } = this.props;

        if (!subscriptionDescriptionUrl) {
            return null;
        }

        return (
            <div block="SubscriptionActions" elem="SubscriptionDescription">
                <Link to={ appendWithStoreCode(subscriptionDescriptionUrl) }><span>{ __('Read more about subscription here') }</span></Link>
            </div>
        );
    }

    render() {
        return (
            <div block="SubscriptionActions">
                { this.renderSubscriptionPrices() }
                { this.renderSubscriptionDescription() }
            </div>
        );
    }
}

export default SubscriptionActionsComponent;
