/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { getQueryParam } from 'Util/Url';

import { CategoriesDispatcher } from '../../store/Categories';
import { PostsDispatcher } from '../../store/Posts';
import PostsListing from './PostsListing.component';

export const BreadcrumbsDispatcher = import(
  /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
  'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

/** @namespace ScandiPWA/Blog/Component/PostsListing/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
  posts: state.PostsReducer.posts,
  isLoaded: state.PostsReducer.isLoaded,
  categories: state.CategoriesReducer.categories,
  posts_per_page: state.ConfigReducer.blog_posts_per_page,
});

/** @namespace ScandiPWA/Blog/Component/PostsListing/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
  requestPosts: (options) => PostsDispatcher.handleData(dispatch, options),
  requestCategories: (options) =>
    CategoriesDispatcher.handleData(dispatch, options),
  updateBreadcrumbs: (breadcrumbs) =>
    BreadcrumbsDispatcher.then(({ default: dispatcher }) =>
      dispatcher.update(breadcrumbs, dispatch)
    ),
  updateMeta: (meta) => dispatch(updateMeta(meta)),
  setHeaderState: (stateName) =>
    dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
});

const POSTS_PER_PAGE = 10;

/** @namespace ScandiPWA/Blog/Component/PostsListing/Container */
export class PostsListingContainer extends PureComponent {
  static propTypes = {
    requestPosts: PropTypes.func.isRequired,
    requestCategories: PropTypes.func.isRequired,
    updateBreadcrumbs: PropTypes.func.isRequired,
    posts: PropTypes.shape({
      items: PropTypes.array,
      count: PropTypes.number,
    }).isRequired,
    categories: PropTypes.shape({
      items: PropTypes.array,
      count: PropTypes.number,
    }).isRequired,
    posts_per_page: PropTypes.number.isRequired,
  };

  __construct(props) {
    super.__construct(props);

    this.state = {
      currentCategory: null,
      postsOptions: {
        pageSize: POSTS_PER_PAGE,
        getDescription: true,
        getMedia: true,
        sort: 'DESC',
        sortFiled: 'publish_time'
      },
    };

    this.containerFunctions = {
      requestPosts: this.requestPosts.bind(this),
      requestCategories: this.requestCategories.bind(this),
      loadMore: this.loadMore.bind(this),
    };
  }

  componentDidMount() {
    this.requestCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      currentCategory,
      postsOptions: {
        pageSize
      }
    } = this.state;
    const {
      currentCategory: prevCategory,
      postsOptions: {
        pageSize: prevPageSize
      }
    } = prevState;
    const category = this.getCategoryFromUrl();
    if (category !== currentCategory) {
      this.setState(state => ({
        ...state,
        currentCategory: category,
        postsOptions: {
          ...state.postsOptions,
          pageSize: POSTS_PER_PAGE
        }
      }));
    }

    if (currentCategory !== prevCategory) {
      this.requestPosts();
      return;
    }

    if (pageSize !== prevPageSize) {
      this.requestPosts();
      return;
    }
  }

  getCategoryFromUrl() {
    return getQueryParam('category', location) || '';
  }

  requestPosts(options) {
    const { requestPosts } = this.props;
    const { postsOptions, currentCategory } = this.state;

    const categoryOptions = currentCategory
      ? { filter: { category_id: { eq: currentCategory } } }
      : {};

    requestPosts({ ...postsOptions, ...options, ...categoryOptions });
  }

  requestCategories() {
    const { requestCategories } = this.props;
    requestCategories();
  }

  loadMore() {
    this.setState(state => ({
      ...state,
      postsOptions: {
        ...state.postsOptions,
        pageSize: state.postsOptions.pageSize + POSTS_PER_PAGE
      }
    }))
  }

  render() {
    const { currentCategory } = this.state;
    const {
      postsOptions: { pageSize },
    } = this.state;

    return (
      <PostsListing
        {...this.props}
        {...this.containerFunctions}
        category={currentCategory}
        pageSize={pageSize}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostsListingContainer);
