/* eslint-disable */
import ClerkSearchOriginalQuery from './ClerkSearchOriginalQuery.component';
import { withRouter } from 'react-router';

/** @namespace Component/ClerkSearchOriginalQuery/Container */
export class ClerkSearchOriginalQueryContainer extends PureComponent {
    getOriginalQuery() {
        const {
            location: {
                state: {
                    originalQuery = ''
                } = {}
            } = {}
        } = this.props;

        return originalQuery;
    }

    getShownQuery() {
        const {
            location: {
                state: {
                    shownQuery = ''
                } = {}
            } = {}
        } = this.props;

        return shownQuery;
    }

    containerProps = () => ({
        originalQuery: this.getOriginalQuery(),
        shownQuery: this.getShownQuery()
    });

    render() {
        return (
            <ClerkSearchOriginalQuery
                { ...this.props }
                { ...this.containerProps() }
            />
        );
    }
}

export default withRouter(ClerkSearchOriginalQueryContainer);
