/* eslint-disable */
import { ProductPrice as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.component';
import './ProductPrice.override.style';

export class ProductPrice extends SourceProductPrice {
    renderOldPrice() {
        const {
            roundedRegularPrice,
            discountFixed,
            discountPercentage,
            isSchemaRequired,
            variantsCount
        } = this.props;

        const schema = isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : {};

        if ((discountPercentage == 0 || discountPercentage == undefined) && (discountFixed == undefined || discountFixed == 0 ) ) {
            return null;
        }

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
              { ...schema }
            >
                { roundedRegularPrice }
            </del>
        );
    }
}

export default ProductPrice;
