// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Recent from './Recent.component';

/** @namespace Hoeks/BlogExtensionPwa/Component/Recent/Container/RecentContainer */
export class RecentContainer extends PureComponent {
    state = {
        postData: []
    }

    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    componentDidMount() {
        const { data } = this.props;
        this.setState({postData: JSON.parse(data)});
        // console.log('test', JSON.parse(data));
    }

    render() {
        return (
            <Recent
                {...this.state}
                {...this.props}
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default RecentContainer;
