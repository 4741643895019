import { Field } from 'Util/Query';

/** @namespace Query/Epay */
export class EpayQuery {
    getEpayCheckout(order_id) {
        return new Field('epayCheckout')
            .addArgument('order_id', 'String', order_id)
            .addFieldList(this._getEpayCheckoutFields());
    }

    getEpayAccept(orderId) {
        return new Field('epayAccept')
            .addArgument('order_id', 'String', orderId)
    }

    getEpayCancel() {
        return new Field('epayCancel')
    }

    _getEpayCheckoutFields() {
        return [
            'order_id',
            'epay_payment_window'
        ];
    }

    _getEpayAcceptFields() {
        return [
            'id'
        ];
    }

    _getEpayCancelFields() {
        return [
            'id'
        ];
    }
}

export default new EpayQuery();
