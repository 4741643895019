import { Popup as SourcePopup } from 'SourceComponent/Popup/Popup.component';

import './Popup.override.style';

export * from 'SourceComponent/Popup/Popup.component';

/** @namespace HoeksPwa/Component/Popup/Component */
export class Popup extends SourcePopup {

}

export default Popup;
