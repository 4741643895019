/* eslint-disable */

const estimateShipping = (args, callback, instance) => {
    const { onShippingEstimationFieldsChange } = instance.props;

    const {
        countryId,
        regionId,
        region,
        city,
        postcode,
        company,
        street
    } = instance.state;

    const address = {
        country_id: countryId,
        region_id: regionId,
        region,
        city,
        postcode,
        company,
        street
    };

    onShippingEstimationFieldsChange(address);
};

const estimateCustomerShipping = (args, callback, instance) => {
    const { 
        onShippingEstimationFieldsChange,
        customer: { addresses = [] }
    } = instance.props;

    const addressId = args[0]

    const address = addresses.find(({ id }) => id === addressId);

    if (!address) {
        return;
    }

    const {
        city,
        country_id,
        postcode,
        company,
        region: {
            region_id,
            region
        } = {}
    } = address;

    if (!country_id) {
        return;
    }

    onShippingEstimationFieldsChange({
        city,
        country_id,
        region_id,
        region,
        postcode,
        company
    });
}

export default {
    'Component/CheckoutAddressForm/Component': {
        'member-function': {
            'estimateShipping': {
                implementation: estimateShipping,
                position: 110
            }
        }
    },
    'Component/CheckoutAddressBook/Container': {
        'member-function': {
            'estimateShipping': {
                implementation: estimateCustomerShipping,
                position: 120
            }
        }
    }
}