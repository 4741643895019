/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_PURCHASE } from '../../../util/Event';
import BaseEvent from './BaseEvent.event';
import { roundPrice } from 'Util/Price';
import ProductHelper from '../utils/Product4';

export const PURCHASE_EVENT_HANDLE_DELAY = 700;
export const SPAM_PROTECTION_DELAY = 10000;

/**
 * On order success page "Purchase"
 */
class Purchase4Event extends BaseEvent {
    /**
     * Event delay
     *
     * @type {number}
     */
    eventHandleDelay = PURCHASE_EVENT_HANDLE_DELAY;

    /**
     * Bind on product detail
     */
    bindEvent() {
        Event.observer(EVENT_GTM_PURCHASE, ({ orderID: orderId, totals }) => {
            this.handle(
                orderId,
                totals
            );
        });
    }

    /**
     * Handle
     *
     * @param orderId
     * @param totals
     * @param cartData
     */
    handler(orderId, totals) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const {
            items: cartItems = [],
            quote_currency_code,
            grand_total,
            tax_amount,
            shipping_amount,
            coupon_code = ''
        } = totals;

        const items = cartItems.map(({ qty, ...cartItem }) => ({
            ...ProductHelper.getItemData(cartItem),
            quantity: qty
        }));

        const itemIds = items.map(({ item_id }) => item_id).join(',');

        this.clearEcommerce();
        this.pushEventData({
            productIDs: itemIds,
            ecommerce: {
                currency: quote_currency_code,
                transaction_id: orderId,
                value: +roundPrice(grand_total),
                tax: +roundPrice(tax_amount),
                shipping: +roundPrice(shipping_amount),
                coupon: coupon_code,
                items
            }
        });
    }
}

export default Purchase4Event;
