/* eslint-disable */
import { Overlay as HoeksOverlay } from 'HoeksComponent/Overlay/Overlay.component';

export class Overlay extends HoeksOverlay {
    componentDidMount() {
        const isVisible = this.getIsVisible();
        if (isVisible) {
            this.onVisible();
        }
    }

    componentWillUnmount() {
        this.unfreezeScroll();
    }
}

export default Overlay;