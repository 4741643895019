/* eslint-disable */

const mapStateToProps = (args, callback, instance) => {
    const [ state ] = args;

    return {
        ...callback.apply(instance, args),
        privateLabelCategoryId: state.ConfigReducer.private_label_category_category_id
    };
}

const hideOnPrivateLabel = (args, callback, instance) => {
    const { category: { id } = {}, privateLabelCategoryId } = instance.props;

    if (privateLabelCategoryId == id) {
        return null;
    }

    return callback.apply(instance, args);
}

export default {
    'Route/CategoryPage/Container/mapStateToProps': {
        'function': mapStateToProps
    },
    'Route/CategoryPage/Component': {
        'member-function': {
            renderCategorySort: hideOnPrivateLabel,
            renderFilterButton: hideOnPrivateLabel
        }
    }
}