/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_PRODUCT_ADD_TO_CART } from '../../../util/Event';
import ProductHelper from '../utils/Product4';
import BaseEvent from './BaseEvent.event';

export const GROUPED_CART_PRODUCTS = 'GROUPED_CART_PRODUCTS';
export const SPAM_PROTECTION_DELAY = 200;

/**
 * Product add to cart event
 */
class AddToCart4Event extends BaseEvent {
    /**
     * Bind add to cart
     */
    bindEvent() {
        Event.observer(EVENT_GTM_PRODUCT_ADD_TO_CART, ({
            product,
            quantity,
            configurableVariantIndex,
            massAddAction = false,
            isFromCart = false,
            isGrouped = false,
            isItem = false
        }) => {
            this.handle(
                { configurableVariantIndex, ...product },
                quantity || 1,
                isItem,
                isGrouped,
                isFromCart,
                massAddAction
            );
        });
    }

    /**
     * Handle product add to cart
     */
    handler(product, quantity, isItem, isGrouped, isFromCart, massAddAction) {
        if (!massAddAction && this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const items = [];

        if (isGrouped) {
            return;
        } else {
            const productData = {
                ...isItem
                ? ProductHelper.getItemData(product)
                : ProductHelper.getProductData(product)
            };

            items.push({
                ...productData,
                quantity,
                index: 0
            });
        }

        this.clearEcommerce()
        this.pushEventData({
            ecommerce: {
                items
            }
        });
    }
}

export default AddToCart4Event;
