const getExtensionAttributes = (args, callback, instance) => {
    const [ product ] = args;
    const { subscriptionOptions } = product;
    const result = callback(...args);

    if (!subscriptionOptions) return result;
    return {...result, ...subscriptionOptions} ;
}

export default {
    'Util/Product/getExtensionAttributes': {
        'function': getExtensionAttributes
    }
}