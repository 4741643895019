/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */

import { QueryDispatcher } from 'Util/Request';

import BlogPostsQuery from '../../query/BlogPosts.query';
import { updatePostsLoadStatus, updatePostsSearch } from './PostsSearch.action';

/**
 * Posts search Dispatcher
 * @class PostsSearchDispatcher
 * @extends QueryDispatcher
 * @namespace ScandiPWA/Blog/Store/PostsSearch/Dispatcher
 */
export class PostsSearchDispatcher extends QueryDispatcher {
  __construct() {
    super.__construct('BlogPostsSearch');
  }

  onSuccess({ posts: { items } }, dispatch) {
    dispatch(updatePostsLoadStatus(false));
    dispatch(updatePostsSearch(items));
  }

  onError(_, dispatch) {
    dispatch(updatePostsLoadStatus(false));
  }

  clearSearchResults(dispatch) {
    dispatch(updatePostsSearch([]));
  }

  prepareRequest(options, dispatch) {
    dispatch(updatePostsLoadStatus(true));

    return BlogPostsQuery.getQuery(options);
  }
}

export default new PostsSearchDispatcher();
