/* eslint-disable */
import SubscriptionActions from '../component/SubscriptionActions';
import { PureComponent } from 'react';

export class AddToProductActionsPlugin extends PureComponent {
    subscriptionIntervalChange(billingId) {
        this.setState({billingId: billingId});
    }

    changeSubscription(isSubscription, billingId) {
        this.setState({isSubscription: isSubscription, billingId: billingId});
    }
}

const { subscriptionIntervalChange, changeSubscription } = new AddToProductActionsPlugin();

const renderSubscriptionActions = (args, callback, instance) => {
    const { product: { is_subscription, subscription_type, discount_amount, discount_type }, productOrVariant: { price_range, subscription_price_range, type_id },  subscriptionIntervalChange, changeSubscription, offerCount = 0} = instance.props;
    if (!is_subscription && price_range == undefined) return null;
    if (!is_subscription) return callback(...args);
    return (
        <>
            <SubscriptionActions
                subscription_type={subscription_type}
                productPrice={price_range}
                subscription_price_range={subscription_price_range}
                subscriptionIntervalChange={subscriptionIntervalChange}
                changeSubscription={changeSubscription}
                discount_amount={discount_amount}
                discount_type={discount_type}
                product_type={type_id}
                offerCount={offerCount}
            />
            {/* {callback(...args)} */}
        </>
    );
}

const addToContainerFunctions = (member, instance) => {
    return (
        {
            ...member,
            subscriptionIntervalChange: subscriptionIntervalChange.bind(instance),
            changeSubscription: changeSubscription.bind(instance)
        } 
    );
}

const addToCart = (args, callback, instance) => {
    const { billingId, isSubscription } = instance.props;
    var dateObj = new Date();
    var year = dateObj.getUTCFullYear();
    var month = dateObj.getUTCMonth() + 1;
    var monthStr = month <= 9 ? `0${month}` : month;
    var day = dateObj.getUTCDate() <= 9 ? `0${dateObj.getUTCDate()}` : dateObj.getUTCDate();
    const subscriptionOptions = {
        billing_period: billingId,
        subscription_start_date: `${year}-${monthStr}-${day}`
    }

    const result = callback(...args);

    if (!isSubscription) {
        return result;
    }
    const { productOptionsData } = result.props;
    const productOptionsDataMore = {
        ...productOptionsData,
        subscriptionOptions
    }

    return <result.type {...result.props} productOptionsData={productOptionsDataMore} />
}

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            'renderPriceWithSchema': renderSubscriptionActions,
            'renderAddToCart': {
                implementation: addToCart,
                position: 0
            }
        }
    },
    'Component/ProductActions/Container': {
        'member-property': {
            'containerFunctions': addToContainerFunctions
        }
    }
}