import SubscriptionCartItem from '../component/SubscriptionCartItem';

const addCartItemFields = (args, callback, instance) => {
    return [
        ...callback.apply(...args),
        'billing_period',
        'subscription_start_date'
    ]
}

const renderSubscriptionCartItem = (args, callback, instance) => {
    const { item } = instance.props;
    
    return <>
        { callback(args) }
        <SubscriptionCartItem 
            billingPeriod={ item.billing_period }
            subscriptionStartDate={ item.subscription_start_date }
        />
    </>
}

export default {
    'Query/Cart': {
        'member-function': {
            '_getCartItemFields': addCartItemFields
        }
    },
    'Component/CartItem/Component': {
        'member-function': {
            'renderProductConfigurations': renderSubscriptionCartItem
        }
    }
}