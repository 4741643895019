/* eslint-disable */
import PrivateLabelLink from './PrivateLabelLink.component';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import PrivateLabelQuery from '../../query/PrivateLabel.query';
import { executePost } from 'Util/Request/Request';
import { prepareQuery } from 'Util/Query';
import { makeCancelable } from 'Util/Promise';

/** @namespace Component/PrivateLabelLink/Container */
export class PrivateLabelLinkContainer extends PureComponent {
    state = {
        isPrivateLabelCustomer: false,
        privateLabelUrl: '',
        privateLabelCategoryId: 0
    }

    componentDidMount() {
        this.getPrivateLabelData = this.getPrivateLabelData.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
        this.onClick = this.onClick.bind(this);

        this.getPrivateLabelData();
    }

    componentWillUnmount() {
        if (this.promise) {
            this.promise.cancel();
        }
    }

    containerFunctions = {
        onClick: this.onClick.bind(this)
    };

    getPrivateLabelData() {
        const preparedQuery = prepareQuery(PrivateLabelQuery.getQuery({}));
        this.promise = makeCancelable(
            executePost(preparedQuery)
        );

        this.promise.promise.then(
            (response) => {
                this.onSuccess(response);
            },
            (err) => this.onError(err)
        );
    }

    onSuccess(data) {
        const {
            privateLabel: {
                has_private_label: isPrivateLabelCustomer = false,
                url: privateLabelUrl = '',
                id = '0'
            } = {}
        } = data;

        const privateLabelCategoryId = parseInt(id);

        this.setState(state => ({
            ...state,
            isPrivateLabelCustomer,
            privateLabelUrl,
            privateLabelCategoryId: !isNaN(privateLabelCategoryId) ? privateLabelCategoryId : 0
        }));
    }

    onError(error) {
        console.log(error);
    }

    onClick() {
        const { privateLabelUrl, privateLabelCategoryId } = this.state;

        const state = privateLabelCategoryId ? { category: privateLabelCategoryId } : {};

        history.push({
            pathname: appendWithStoreCode(privateLabelUrl),
            state
        });
    }

    render() {
        return (
            <PrivateLabelLink
                { ...this.props }
                { ...this.state }
                { ...this.containerFunctions }
            />
        );
    }
}

export default PrivateLabelLinkContainer;