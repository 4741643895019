import { ProductListPage as SourceProductListPage } from 'SourceComponent/ProductListPage/ProductListPage.component';

import './ProductListPage.override.style';

export * from 'SourceComponent/ProductListPage/ProductListPage.component';

/** @namespace SourceComponent/ProductListPage/Component */
export class ProductListPage extends SourceProductListPage {

}

export default ProductListPage;
