import { ContentWrapper as SourceContentWrapper } from 'SourceComponent/ContentWrapper/ContentWrapper.component';

import './ContentWrapper.override.style';

export * from 'SourceComponent/ContentWrapper/ContentWrapper.component';

/** @namespace HoeksPwa/Component/ContentWrapper/Component */
export class ContentWrapper extends SourceContentWrapper {

}

export default ContentWrapper;
