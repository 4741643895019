/* eslint-disable */

/** @namespace Component/PrivateLabelLink/Component */
export class PrivateLabelLink extends PureComponent {
    render() {
        const { isPrivateLabelCustomer, privateLabelUrl, onClick } = this.props;

        if (!isPrivateLabelCustomer || !privateLabelUrl) {
            return null;
        }

        return (
            <li
                key="privateLabel"
                block="MyAccountTabListItem"
            >
                <button
                    block="MyAccountTabListItem"
                    elem="Button"
                    onClick={ onClick }
                    role="link"
                >
                    { __('My Private Label Products') }
                </button>
            </li>
        );
    }
}

export default PrivateLabelLink;