/* eslint-disable */
import { UPDATE_RECENTLY_VIEWED_PRODUCTS } from 'Store/RecentlyViewedProducts/RecentlyViewedProducts.action';
import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import { getIndexedProduct } from 'Util/Product';
import BrowserDatabase from 'Util/BrowserDatabase';

const updateCartTotals = (args, callback, instance) => {
    const [ action ] = args;
    const { cartData: { items = [], ...rest } } = action;

    const cartTotals = {
        ...rest,
        items: []
    }

    if (items.length) {
        const normalizedItemsProduct = items.map((item) => {
            const { product: { variants, ...normalizedProduct } } = item;
            
            if (variants && variants.length){
                normalizedProduct.variants = [variants.find(variant => variant.product.sku == item.sku)];
                item.product = getIndexedProduct(normalizedProduct, item.sku);
            }
            else {
                item.product = getIndexedProduct(item.product, item.sku);
            }
            return item;
        })

        cartTotals.items = normalizedItemsProduct;
    }

    BrowserDatabase.setItem(
        cartTotals,
        CART_TOTALS
    );

    return { cartTotals };
}

const RecentlyViewedProductsReducer = (args, callback, instance) => {
    const [ state, action ] = args;

    if (action.type === UPDATE_RECENTLY_VIEWED_PRODUCTS) {
        const { product } = action;
        const filteredProduct = {
            ...product,
            attributes: {},
            variants: []
        }
        action.product = filteredProduct;

        return callback.apply(instance, [state, action]);
    } else {
        return callback.apply(instance, args);
    }
}

export default {
    'Store/Cart/Reducer/updateCartTotals': {
        'function': updateCartTotals
    },
    'Store/RecentlyViewedProducts/Reducer/recentlyViewedProductsReducer': {
        'function': RecentlyViewedProductsReducer
    }
}