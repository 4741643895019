import { MenuItem as HoeksMenuItem } from 'HoeksComponent/MenuItem/MenuItem.component';

import Image from 'Component/Image';
import Link from 'Component/Link';
// import { DeviceType } from 'Type/Device';
import media from 'Util/Media';

export * from 'HoeksComponent/MenuItem/MenuItem.component';

export class MenuItem extends HoeksMenuItem {
    renderItemContentImage(icon, itemMods) {
        const { device } = this.props;
        const {
            isBanner,
            isLogo,
            isGridImage,
            type
        } = itemMods;

        if (!icon
            || (!device.isMobile && !isBanner && !isLogo && !isGridImage)
            || (type === 'subcategory')
        ) {
            return null;
        }

        return (
            <Image
              mix={ { block: 'Menu', elem: 'Image', mods: itemMods } }
              src={ icon && media(icon) }
              ratio="custom"
            />
        );
    }

    renderItemLinkContent() {
        const {
            activeMenuItemsStack,
            item,
            itemMods,
            handleCategoryHover,
            onItemClick
        } = this.props;

        const {
            url,
            item_id,
            item_class
        } = item;

        const isHovered = activeMenuItemsStack.includes(item_id);

        return (
            <Link
              to={ url }
              block="Menu"
              elem="Link"
              id={ item_id }
              className={ item_class }
              onMouseEnter={ handleCategoryHover }
              mods={ { isHovered } }
              onClick={ onItemClick }
            >
                { this.renderItemContent(item, itemMods) }
            </Link>
        );
    }
}

export default MenuItem;
