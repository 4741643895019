/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */

import { Field } from 'Util/Query';

/** @namespace ScandiPWA/Blog/Query */
export class BlogCategoriesQuery {
  getQuery() {
    const items = BlogCategoriesQuery.itemField();
    const page = BlogCategoriesQuery.pageField();

    return new Field('blogCategories')
      .addField(items)
      .addFieldList(page)
      .setAlias('categories');
  }

  static itemField() {
    const fields = [];

    fields.push('category_id', 'identifier', 'title');

    return new Field('items').addFieldList(fields);
  }

  /**
   * Prepare the pages field
   */
  static pageField() {
    return ['total_count'];
  }
}

export default new BlogCategoriesQuery();
