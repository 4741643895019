/* eslint-disable */

import { RADIO_TYPE } from 'Component/Field/Field.config';

class FieldContainerPlugin {
    componentDidUpdate = (args, callback, instance) => {
        const [ prevProps ] = args;
        const { checked: prevChecked } = prevProps;
        const { checked: currChecked, type } = instance.props;

        if (type === RADIO_TYPE && currChecked !== prevChecked) {
            instance.setState({ checked: currChecked });
        }

        return callback.apply(instance, args);
    }
}

const { componentDidUpdate } = new FieldContainerPlugin();

export default {
    'Component/Field/Container': {
        'member-function': {
            componentDidUpdate
        }
    }
}