// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Link from 'Component/Link';
import './Recent.style';

/** @namespace Hoeks/BlogExtensionPwa/Component/Recent/Component/RecentComponent */
export class RecentComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderCategory(category) {
        const {categoryTitle, categoryUrl} = category
        return (
            <a
                title={categoryTitle}
                href={categoryUrl}
            >{categoryTitle}</a>
        );
    }

    renderCategories(parentCategory) {

        return (
        <div className="item post-categories">
            <span className="label">{__('Categories:')}</span>
            {parentCategory.map((category, i) => this.renderCategory(category))}
        </div>
        )
    }

    renderPost(post, i) {
        const  { postId, parentCategory, postUrl, postTitle, featuredImage, featuredImageAlt } = post;
        const classes = 'post-holder post-holder-' + postId;
        const url = postUrl.substring(postUrl.indexOf('/blog'));
        // console.log("postData","url",url)
        return (
            <li className={classes}>
                <div className="post-header">
                    { parentCategory.length > 0 ? this.renderCategories(parentCategory) : <></> }
                    <div className="post-title-holder clearfix">
                        <div className="post-title">
                            <Link className="post-item-link"
                                to={url}>
                                {postTitle}
                            </Link>
                        </div>
                    </div>
                    <Link className="post-read-more"
                        to={url}
                        title={postTitle}>
                        {__('Read more')}  &#187;
                    </Link>
                </div>

                <div className="post-content">
                    <div class="post-description clearfix">
                        <div class="post-ftimg-hld">
                            <img src={featuredImage} alt={featuredImageAlt} />
                        </div>
                    </div>
                </div>
                <div class="post-footer">

                </div>
            </li>
        );
    }

    render() {
        const { title, postData } = this.props;
        // console.log("postData", postData);
        return (
            <div className="post-list-wrapper blog-widget-recent">
                <h3 className="title">{title}</h3>
                <ul className="post-list clearfix">
                    {postData.map((post, i) => this.renderPost(post, i))}
                </ul>
            </div>
        );
    }
}

export default RecentComponent;
