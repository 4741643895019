// /* eslint-disable */

import CartQuery from 'Query/Cart.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { getExtensionAttributes } from 'Util/Product';
import { fetchMutation, getErrorMessage } from 'Util/Request';


const addProductToCart = async (args, callback, instance) => {
    const [dispatch, options] = args;
    const {
        product,
        quantity,
        productOptionsData,
        buyRequest
    } = options;

    const {
        sku,
        type_id: product_type
    } = product;

    const {
        subscriptionOptions: {
            billing_period = false
        } = {}
    } = productOptionsData

    const productToAdd = {
        sku,
        product_type,
        quantity,
        product_option: {
            buy_request: buyRequest,
            extension_attributes: getExtensionAttributes(
                {
                    ...product,
                    ...productOptionsData || {}
                }
            )
        }
    };

    if (billing_period !== false && !isValidSubcription(billing_period)) {
        dispatch(showNotification('warning', __('please select a billing cycle before adding a subscription to the cart')));
        return Promise.reject();
    }

     if (instance._canBeAdded(options)) {
            try {
                const isCustomerSignedIn = isSignedIn();
                const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

                if (!isCustomerSignedIn && !guestQuoteId) {
                    return Promise.reject();
                }

                const { saveCartItem: { cartData = {} } = {} } = await fetchMutation(
                    CartQuery.getSaveCartItemMutation(productToAdd, guestQuoteId)
                );

                return instance._updateCartData(cartData, dispatch);
            } catch (error) {
                const { debugMessage = '', message = '' } = error[0];
                if (debugMessage.includes('Keine Eintrag mit cartId =') || debugMessage.includes('Current customer does not have an active cart.') || message.includes('Internal server error')) {
                    const {createEmptyCart: quoteId = ''} = await fetchMutation(
                        CartQuery.getCreateEmptyCartMutation()
                    );
                    setGuestQuoteId(quoteId);
                    const { saveCartItem: { cartData = {} } = {} } = await fetchMutation(
                        CartQuery.getSaveCartItemMutation(productToAdd, quoteId)
                    );

                    instance._updateCartData(cartData, dispatch);
                    dispatch(showNotification('warning', __('Your cart was no longer active. The product has been added to a new cart')));
                    return Promise.reject();
                } else {
                    dispatch(showNotification('error', getErrorMessage(error)));
                    return Promise.reject();
                }
            }
        }

        return Promise.reject();
}

const isValidSubcription = (billing_period) => {
    if (billing_period !== 'default') {
        return true;
    } else {
        return false;
    }
}

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            'addProductToCart': addProductToCart
        }
    }
}