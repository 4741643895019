/* eslint-disable */
import { HOEKS_SCROLL_TO } from '../component/ScrollToWidget/ScrollToWidget.config';
import { lazy } from 'react';
import Loader from 'Component/Loader';

export const ScrollToWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-scroll-to" */
    '../component/ScrollToWidget'
));

const widgetFactoryComponentMemberProperties = {
    renderMap: (member, instance) => ({
        ...member,
        [HOEKS_SCROLL_TO]: {
            component: ScrollToWidget,
            fallback: (props) => <Loader isLoading={ true } { ...props } />
        }
    })
}

export default {
    'Component/WidgetFactory/Component': {
        'member-property': widgetFactoryComponentMemberProperties
    }
}