/* eslint-disable */

const _getProductInterfaceFields = (args, callback, instance) => {
    const result = callback.apply(instance, args);

    return [
        ...result,
        'qty_increments',
        'qty_increments_enabled'
    ];
}

export default {
    'Query/ProductList': {
        'member-function': {
            _getProductInterfaceFields
        }
    }
}