/* eslint-disable */

// import original route
import SourceTextPlaceholder from 'SourceComponent/TextPlaceholder/TextPlaceholder.component';

// import new extended styles filer
import './TextPlaceholder.override.style.scss';

// export everything from the source TextPlaceholder
export * from 'SourceComponent/TextPlaceholder/TextPlaceholder.component';

/** @namespace Component/TextPlaceholder/Component */
export class TextPlaceholder extends SourceTextPlaceholder {


}

export default TextPlaceholder;
