import MyAccountMySubscriptionProfiles from '../component/MyAccountMySubscriptionProfiles';
import SubscriptionDetails from '../component/SubscriptionDetails';
import SubscriptionProfileReducer from '../store/SubscriptionProfile/SubscriptionProfile.reducer';

const addToTab = (member, instance) => {
    return ({
        ...member,
        ['my-subscriptions']: {
            url: '/my-subscriptions',
            name: __('My subscriptions')
        }
    });
}

const addToRenderMap = (member, instance) => {
    return({
        ...member,
        ['my-subscriptions']: MyAccountMySubscriptionProfiles,
    });
}

const addReducer = (args, callback, instance) => {
    return {
        ...callback(...args),
        SubscriptionProfileReducer
    }
}

export default {
    'Route/MyAccount/Container': {
        'static-member': {
            'tabMap': addToTab
        }
    },
    'Route/MyAccount/Component': {
        'member-property': {
            'renderMap': addToRenderMap
        }
    },
    'Store/Index/getReducers':{
        'function':  addReducer
    }
}