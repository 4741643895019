/* eslint-disable */

// imports the original component from vendor folder
import SourcePostsDetails from 'scandipwa-blog/src/route/PostsDetails/PostsDetails.component';

// import dependencies
import ContentWrapper from 'Component/ContentWrapper';
import BlogCategories from 'scandipwa-blog/src/component/BlogCategories';
import BlogRecentPosts from 'scandipwa-blog/src/component/BlogRecentPosts';
import BlogSearchBar from 'scandipwa-blog/src/component/BlogSearchBar';
import BlogRelatedPosts from 'scandipwa-blog/src/component/BlogRelatedPosts';
import BlogRelatedProducts from 'scandipwa-blog/src/component/BlogRelatedProducts';
import Fade from 'react-reveal/Fade';
import Image from 'Component/Image';
import TextPlaceholder from 'Component/TextPlaceholder';
import { convertDateFormat } from 'scandipwa-blog/src/util/Date';
import Link from 'Component/Link';
import CmsBlock from 'Component/CmsBlock';
import getStore from 'Util/Store';
import {CopyToClipboard} from 'react-copy-to-clipboard';

// import new extended styles file
import './PostsDetails.override.style.scss';

export class PostsDetails extends SourcePostsDetails {
    state = {
        clipboard_copied: false
      };

      clipboardOnClick = () => {
        this.setState({clipboard_copied: true});
        setTimeout(() => {
            this.setState({ clipboard_copied: false });
          }, 5000);
      } 

    renderTitle() {
        const { post: { title } } = this.props;

        return (
            <h1 mix={ { block: 'PostsDetails', elem: 'Title' } }>
                <TextPlaceholder length="medium" content={ title } />
            </h1>
        );
    }

    renderFeaturedImage() {
        const {
            post,
            post: {
                first_image
            }
        } = this.props;

        if (!post && post_id) {
            return null;
        }

        return (
            <Image
                block="PostsDetails"
                elem="FeaturedImage"
                src={ first_image }
            />
        );
    }

    renderShare() {
        const { post: { identifier } = {}, post: { title } = {} } = this.props;
        const { ConfigReducer: { base_url } } = getStore().getState();
        
        const blogLink = base_url + 'blog/' + identifier;
        
        const twitterLink = `http://twitter.com/share?url=${blogLink}&text=${title}`;
        const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${blogLink}`;


        return (
            <div block="PostsDetails" elem="Share">
                <span block="PostsDetails" elem="ShareText">{__('Share')}</span>
                <span block="PostsDetails" elem="ShareSeparator">&mdash;</span>
                <a block="PostsDetails" elem="ShareLink" href={twitterLink} target="_blank" rel="noopener noreferrer nofollow">Twitter</a>
                <a block="PostsDetails" elem="ShareLink" href={facebookLink} target="_blank" rel="noopener noreferrer nofollow">Facebook</a>
                <CopyToClipboard text={blogLink}
                    onCopy={this.clipboardOnClick}>
                        {this.state.clipboard_copied 
                        ? <span block="PostsDetails" elem="ShareLink" mods={{ hasCopied: true }}>{__('Link copied!')}</span> 
                        : <span block="PostsDetails" elem="ShareLink">{__('Copy link')}</span>}
                </CopyToClipboard>
            </div>
        );
    }

    renderExploreWidget() {
        return (
            <div block="PostsDetails" elem="ExploreWidget">
                <CmsBlock identifier="explore-widget-section-2-posts" blockType="explore-widget-section-2-posts" />
            </div>
        );
    }

    render() {
        window.scrollTo(0,0);

        return ( 
            <main block="PostsDetails">
                <div
                    block="PostsDetails"
                    elem="Header"
                >
                    { this.renderFeaturedImage() }
                    <div 
                        block="PostsDetails"
                        elem="HeaderOverlay"
                    ></div>
                    <ContentWrapper
                        wrapperMix={ { block: 'PostsDetails', elem: 'HeaderWrapper' } }
                        label="Meta"
                    >
                        <div 
                            block="PostsDetails"
                            elem="HeaderContent"
                        >
                            <Link
                                block="PostsDetails"
                                elem="HeaderBackLink"
                                to="blog"
                            >
                                <span
                                    block="PostsDetails"
                                    elem="HeaderBackLinkText"
                                >
                                    { __('Back to overview') }
                                </span>
                            </Link>
                            { this.renderTitle() }
                            <div block="PostsDetails" elem="HeaderShare">
                                { this.renderShare() }
                            </div>
                        </div>
                    </ContentWrapper>
                </div>
                <ContentWrapper
                  wrapperMix={ { block: 'PostsDetails', elem: 'Wrapper' } }
                  label="Post"
                >
                    <div block="PostsDetails" elem="ColumnWrapper">
                        <div block="PostsDetails" elem="Description">
                            { this.renderContent() }
                            <BlogRelatedProducts />
                            <BlogRelatedPosts />
                        </div>
                        <div block="PostsDetails" elem="Sidebar">
                            {/* <BlogSearchBar /> */}
                            <BlogRecentPosts />
                            {/* { this.renderExploreWidget() } */}
                        </div> 
                    </div>
                </ContentWrapper>
            </main>
        );
    }

}

export default PostsDetails;
