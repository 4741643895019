
/**
 * Product helper, contain all related to product data prepare methods
 */
import Product3 from './Product3';
import { roundPrice } from 'Util/Price';

export default class Product4 extends Product3 {
    static getProductData(product, category) {
        const { categories } = product;
        const variant = this.getProductVariant(product);
        const {
            sku,
            name,
            price_range: {
                minimum_price: {
                    discount: {
                        amount_off: discount
                    },
                    final_price: {
                        currency,
                        value: price
                    }
                }
            },
        } = variant;

        return {
            item_id: sku,
            item_name: name,
            price: roundPrice(price),
            currency,
            discount: roundPrice(discount),
            item_brand: this.getItemBrand(variant),
            ...(category ? this.getCategoryData(category) : this.getProductCategoryData(categories ? product : variant)),
            ...this.getVariantString(product, variant)
        };
    }

    static getProductCategoryData(product){
        const { categories = [], prevCategoryId } = product;

        const category = categories.find(({ id }) => id === prevCategoryId) || this.findLongestBreadcrumbs(categories);

        return this.getCategoryData(category);
    }

    static getCategoryData(category){

        const {
            breadcrumbs = [],
            name
        } = category;

        const breadcrumbNames = (breadcrumbs || []).reduce((acc, { category_name }) => ([ ...acc, category_name ]), []);
        const hierarchies = [ ...breadcrumbNames, name ];
        const categoryData = hierarchies.reduce((acc, val, idx) => ({ ...acc, [`item_category${ idx ? idx + 1 : '' }`]: val }), {});

        return categoryData;
    }
}