/* eslint-disable */

import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';
import { Field } from 'Util/Query';
export * from 'SourceQuery/Cart.query';

export class CartQuery extends SourceCartQuery {
    _getCartTotalsFields() {
        return [
            ...super._getCartTotalsFields(),
            'is_active'
        ];
    }
}

export default new CartQuery();