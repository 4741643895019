/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { NewsletterSubscription as SourceNewsletterSubscription } from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';

import Form from 'Component/Form';
import CmsBlock from 'Component/CmsBlock';

import './NewsletterSubscription.override.style';

export * from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';

export class NewsletterSubscription extends SourceNewsletterSubscription {
    get fieldMap() {
        const fields = {
            // newsletterName: {
            //     validation: ['notEmpty', 'name'],
            //     placeholder: __('Name'),
            //     'aria-label': __('Name')
            // },
            newsletterEmail: {
                validation: ['notEmpty', 'email'],
                placeholder: __('Email'),
                'aria-label': __('Email')
            }
        };

        return fields;
    }

    render() {
        const { isLoading, onFormSubmit } = this.props;
        return (
            <>
                <div block="NewsletterSubscription">
                    <Form
                    onSubmitSuccess={ onFormSubmit }
                    mix={ { block: 'FieldForm' } }
                    >
                        <div block="FieldForm" elem="Fieldset" mods={ { isLoading } }>
                            { this.renderFields() }
                            { this.renderActions() }
                        </div>
                    </Form>
                </div>
                <div block="NewsletterSubscription" elem="CMSMenuOverlaySocialMedia">
                    <CmsBlock identifier="cms-menuoverlay-socialmedia" />
                </div>
            </>
        );
    }
}

export default NewsletterSubscription;
