/* eslint-disable */
import { Field as HoeksField } from 'HoeksComponent/Field/Field.component';

import './Field.style';
import FieldInput from 'Component/FieldInput';

export * from 'HoeksComponent/Field/Field.component';

/** @namespace HoeksComponent/Field/Component */
export class Field extends HoeksField {
    static defaultProps = {
        min: 1,
        // max: 99,
        checked: false,
        mix: {},
        label: '',
        value: null,
        message: '',
        validationStatus: null,
        filename: '',
        fileExtensions: ''
    };

    renderTypeNumber() {
        const {
            min,
            max,
            value,
            onKeyEnterDown,
            handleChange
        } = this.props;
        
        return (
            <>
                <FieldInput
                  { ...this.props }
                  type="number"
                //   readOnly
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (e) => handleChange(e.target.value) }
                //   onInput={ (e) => handleChange(e.target.value, false)}
                //   onKeyDown={ onKeyEnterDown }
                  aria-label={ __('Value') }
                />
                <button
                //   disabled={ +value === max }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value + 1) }
                  aria-label={ __('Add') }
                />
                <button
                  disabled={ +value === min }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value - 1) }
                  aria-label={ __('Subtract') }
                />
            </>
        );
    }

}

export default Field;
