/* eslint-disable */
import Clerk from '@hoeks/clerk/src/component/Clerk';

const aroundRenderCategoryDetails = (args, callback, instance) => {
    const { category: { id, name }, isSearchPage } = instance.props;
    if (isSearchPage || !id) return null;
    const title = name ? __("Most popular in %s", name) : __("Most popular") 
    return (
        <>
            <Clerk 
                title={title} 
                variant="recommendations/category/popular" 
                labels={["Category Popular"]}
                data={{category: id}}
                slidesToShow={6}/>
            {callback(...args)}
        </>
    );
}

export default {
    'Route/CategoryPage/Component': {
        'member-function': {
            'renderCmsBlock': aroundRenderCategoryDetails
        }
    }
}